import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'

import BaseProvider from './BaseProvider'

import theme from '~/theme/theme'
import FontStyles from '~/theme/FontStyles'
import AppRouter from '~/components/AppRouter'

export default function App () {
  return (
    <ChakraProvider theme={theme} resetCSS={true}>
      <FontStyles />

      <BrowserRouter>
        <BaseProvider>
          <AppRouter />
        </BaseProvider>
      </BrowserRouter>
    </ChakraProvider>
  )
}
