import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import { withForm, Input, ImageCrop } from '~/components/FormElements'
import { Card, CardGrid, CardButtons, FieldStack } from '~/components/Card'

function BrandCreate (props) {
  const { form, isLoading, isLoadingData } = props
  const { handleSubmit } = form

  return (
    <Card as={'form'} isLoading={isLoadingData} onSubmit={handleSubmit}>
      <CardGrid>
        <FieldStack direction={'row'}>
          <Field
            name={'photo'}
            component={ImageCrop}
            label={'Photo'}
            isRequired={true}
          />
          <Field
            name={'name'}
            component={Input}
            label={'Brand name'}
            placeholder={'Brand name'}
            isRequired={true}
          />
        </FieldStack>
      </CardGrid>

      <CardButtons isLoading={isLoading} />
    </Card>
  )
}

BrandCreate.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingData: PropTypes.bool
}

export default withForm(BrandCreate)
