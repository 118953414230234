import React from 'react'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'

import { CardHeader, CardImage, CardTitle, CardGrid, FieldStack, Card } from '~/components/Card'
import { DataDropdown } from '~/components/DataDropdown'
import { DetailSkeleton } from '~/components/Skeletons'

function BannerDetail (props) {
  const { detail, onEdit, onDelete } = props
  const { data, isLoading } = detail

  const id = prop('id', data)
  const title = prop('title', data)
  const photo = path(['photo', 'path'], data)

  return (
    <DetailSkeleton isLoading={isLoading}>
      <Card>
        <CardHeader>
          <CardTitle>
            {title}
          </CardTitle>
          <DataDropdown
            onEdit={onEdit.bind(null, id)}
            onDelete={onDelete}
          />
        </CardHeader>

        <CardGrid>
          <FieldStack direction={'row'} spacing={8}>
            <CardImage
              src={photo}
              objectFit={'unset'}
              h={'auto'}
              minW={'unset'}
              w={'auto'}
            />
          </FieldStack>
        </CardGrid>
      </Card>
    </DetailSkeleton>
  )
}

BannerDetail.propTypes = {
  detail: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default BannerDetail
