import React, { useState, createContext, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { useHistory } from 'react-router-dom'

import * as API from '~/constants/api'
import * as ROUTES from '~/constants/routes'
import { getToken, removeToken, setToken } from '~/utils/cookies'
import request from '~/utils/request'
import { useCreate } from '~/hooks/crud'
import { AuthSkeleton } from '~/components/Skeletons'

const AuthContext = createContext({})

export function AuthProvider ({ children }) {
  const history = useHistory()
  const [user, setUser] = useState(null)
  const [isLoading, setLoading] = useState(true)

  const loginCreate = useCreate(API.LOGIN)

  useEffect(() => {
    const token = getToken()
    function loadUserFromCookies () {
      if (token) {
        fetchCurrentUser()
          .then(() => {
            setLoading(false)
          })
          .catch(() => {
            setLoading(false)
            onLogout()
          })
      } else {
        setLoading(false)
        onLogout()
      }
    }

    loadUserFromCookies()
  }, [])

  function fetchCurrentUser () {
    return request.get(API.ME)
      .then(({ data }) => {
        setUser(data)
      })
  }

  function onLogin (formValues) {
    return loginCreate.create(formValues)
      .then(resp => {
        const token = prop('token', resp)
        setToken(token)

        return fetchCurrentUser()
      })
      .then(() => {
        history.replace({
          pathname: ROUTES.ROOT_PATH
        })
      })
  }

  function onLogout () {
    history.replace({ pathname: ROUTES.LOGIN_PATH })
    removeToken()
    setUser(null)
  }

  const login = {
    onSubmit: onLogin,
    isLoading: loginCreate.isLoading
  }

  const authProps = {
    isAuth: !!user,
    user,
    login,
    onLogout
  }

  return (
    <AuthContext.Provider value={authProps}>
      {isLoading ? <AuthSkeleton /> : children}
    </AuthContext.Provider>
  )
}

export function useAuth () {
  return useContext(AuthContext)
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
}
