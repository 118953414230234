import React from 'react'
import PropTypes from 'prop-types'
import { ButtonGroup, Button } from '@chakra-ui/react'

function CardButtons (props) {
  const { cancelPath, isLoading, onCancel, ...restProps } = props

  return (
    <ButtonGroup
      display={'flex'}
      justifyContent={'flex-end'}
      mt={8}
      size={'lg'}
      spacing={4}
      {...restProps}>
      <Button isLoading={isLoading} type={'submit'} minW={44}>
        Submit
      </Button>
    </ButtonGroup>
  )
}

CardButtons.propTypes = {
  isLoading: PropTypes.bool,
  cancelPath: PropTypes.string,
  onCancel: PropTypes.func
}

export default CardButtons
