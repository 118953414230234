import React from 'react'
import { useParams } from 'react-router-dom'

import { useRedirectEdit, useMeasurementDetail, useMeasurementDelete } from '../hooks'
import { MeasurementDetail } from '../components/MeasurementDetail'

import { useConfirm } from '~/hooks/app'
import { RouterNavBar } from '~/components/NavBar'

export default function MeasurementDetailContainer () {
  const { id } = useParams()
  const detail = useMeasurementDetail(id)
  const remove = useMeasurementDelete(id)

  const onDelete = useConfirm({
    remove,
    alertParams: { title: 'Remove measurement' },
    redirectPath: 'back'
  })
  const onEdit = useRedirectEdit()

  return (
    <RouterNavBar title={'Measurement detail'}>
      <MeasurementDetail
        detail={detail}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </RouterNavBar>
  )
}
