import React from 'react'
import { prop } from 'ramda'
import { Avatar, Box, Stack, Text } from '@chakra-ui/react'

import { useAuth } from '~/contexts/AuthProvider'

export default function UserInfo () {
  const { user } = useAuth()

  const marketName = prop('marketName', user)
  const phoneNumber = prop('phoneNumber', user)

  return (
    <Stack align={'center'} direction={'row'}>
      <Avatar bgColor={'gray.300'} color={'gray.600'} name={marketName} src={''} />
      <Box>
        <Text fontWeight={'medium'}>{marketName}</Text>
        <Text fontSize={'sm'}>{phoneNumber}</Text>
      </Box>
    </Stack>
  )
}
