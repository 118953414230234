import React from 'react'
import PropTypes from 'prop-types'
import { Progress } from '@chakra-ui/react'

import Paper from '~/components/Paper'

function Card (props) {
  const { children, isLoading, ...restProps } = props

  return (
    <Paper pos={'relative'} overflow={'hidden'} {...restProps}>
      {isLoading && (
        <Progress
          bgColor={'transparent'}
          colorScheme={'primary'}
          isIndeterminate={true}
          rounded={'md'}
          pos={'absolute'}
          top={0}
          left={0}
          right={0}
          size={'xs'}
        />
      )}
      {children}
    </Paper>
  )
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool
}

export default Card
