import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { categoryCreateSerializer } from '../serializers'
import { useCategoryDetail, useCategoryUpdate } from '../hooks'
import { CategoryCreate } from '../components/CategoryCreate'

import { RouterNavBar } from '~/components/NavBar'
import useToast, { SUCCESS_UPDATE_PARAMS } from '~/hooks/useToast'

export default function CategoryUpdateContainer () {
  const { id } = useParams()
  const history = useHistory()
  const toast = useToast()
  const { data, isLoading: isLoadingData } = useCategoryDetail(id)
  const { update, isLoading } = useCategoryUpdate(id)

  function onSubmit (formValues) {
    return update(categoryCreateSerializer(formValues))
      .then(() => toast(SUCCESS_UPDATE_PARAMS))
      .then(() => history.goBack())
  }

  return (
    <RouterNavBar title={'Category edit'}>
      <CategoryCreate
        onSubmit={onSubmit}
        isLoading={isLoading}
        isLoadingData={isLoadingData}
        initialValues={data}
      />
    </RouterNavBar>
  )
}
