import React, { createContext, useContext, useReducer, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button
} from '@chakra-ui/react'

const initialState = {
  isOpen: false,
  title: 'Confirm action',
  message: 'Are you sure? You can\'t undo this action afterwards.',
  onConfirm: function () {}
}

function reducer (state, action) {
  const { payload } = action

  switch (action.type) {
    case 'open': return { ...state, ...payload, isOpen: true }
    case 'close': return { ...state, isOpen: false }
    default: return state
  }
}

const AlertContext = createContext(null)

export function useAlertDialog () {
  return useContext(AlertContext)
}

function AlertDialogProvider ({ children }) {
  const cancelRef = useRef()
  const [state, dispatch] = useReducer(reducer, initialState)

  const {
    isOpen,
    title,
    message
  } = state

  function onOpen (payload) {
    dispatch({ type: 'open', payload })
  }

  function onClose () {
    dispatch({ type: 'close' })
  }

  return (
    <AlertContext.Provider value={{ onOpen, onClose }}>
      {children}

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize={'lg'} fontWeight={'bold'}>
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>
              {message}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme={'gray'} ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme={'red'} onClick={state.onConfirm} ml={3}>
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </AlertContext.Provider>
  )
}

AlertDialogProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default AlertDialogProvider
