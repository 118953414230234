import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useMeasurementDetail, useMeasurementUpdate } from '../hooks'
import { MeasurementCreate } from '../components/MeasurementCreate'

import { RouterNavBar } from '~/components/NavBar'
import useToast, { SUCCESS_UPDATE_PARAMS } from '~/hooks/useToast'

export default function MeasurementUpdateContainer () {
  const { id } = useParams()
  const history = useHistory()
  const toast = useToast()
  const { data, isLoading: isLoadingData } = useMeasurementDetail(id)
  const { update, isLoading } = useMeasurementUpdate(id)

  function onSubmit (formValues) {
    return update(formValues)
      .then(() => toast(SUCCESS_UPDATE_PARAMS))
      .then(() => history.goBack())
  }

  return (
    <RouterNavBar title={'Measurement edit'}>
      <MeasurementCreate
        onSubmit={onSubmit}
        isLoading={isLoading}
        isLoadingData={isLoadingData}
        initialValues={data}
      />
    </RouterNavBar>
  )
}
