import React from 'react'
import { useParams } from 'react-router-dom'

import { useRedirectEdit, useProviderDetail, useProviderDelete } from '../hooks'
import { ProviderDetail } from '../components/ProviderDetail'

import { useConfirm } from '~/hooks/app'
import { RouterNavBar } from '~/components/NavBar'

export default function ProviderDetailContainer () {
  const { id } = useParams()
  const detail = useProviderDetail(id)
  const remove = useProviderDelete(id)

  const onDelete = useConfirm({
    remove,
    alertParams: { title: 'Remove provider' },
    redirectPath: 'back'
  })
  const onEdit = useRedirectEdit(id)

  return (
    <RouterNavBar title={'Provider detail'}>
      <ProviderDetail
        detail={detail}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </RouterNavBar>
  )
}
