import React from 'react'

import {
  useRedirectDetail,
  useRedirectEdit,
  useBannerList
} from '../hooks'
import { BannerList } from '../components/BannerList'

import { NAV_KEYS } from '~/constants/nav'
import { AppBar } from '~/components/AppBar'

export default function BannerListContainer () {
  const list = useBannerList()
  const toDetail = useRedirectDetail()
  const onEdit = useRedirectEdit()

  return (
    <AppBar activeNav={NAV_KEYS.SETTINGS}>
      <BannerList
        list={list}
        toDetail={toDetail}
        onEdit={onEdit}
      />
    </AppBar>
  )
}
