import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'

import { Card, CardHeader, CardTitle } from '~/components/Card'
import { DataDropdown } from '~/components/DataDropdown'
import { DetailSkeleton } from '~/components/Skeletons'

function MeasurementDetail (props) {
  const { detail, onEdit, onDelete } = props
  const { data, isLoading } = detail

  const id = prop('id', data)
  const name = prop('name', data)

  return (
    <DetailSkeleton isLoading={isLoading}>
      <Card>
        <CardHeader>
          <CardTitle>{name}</CardTitle>
          <DataDropdown
            onEdit={onEdit.bind(null, id)}
            onDelete={onDelete}
          />
        </CardHeader>
      </Card>
    </DetailSkeleton>
  )
}

MeasurementDetail.propTypes = {
  detail: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default MeasurementDetail
