import React from 'react'
import { Box, Text } from '@chakra-ui/react'

export default function PhotoGridTitle (props) {
  return (
    <Box px={6} py={4}>
      <Text fontWeight={'medium'} {...props} />
    </Box>
  )
}
