import { mode } from '@chakra-ui/theme-tools'

export default {
  global: (props) => ({
    body: {
      bg: mode('gray.100', 'gray.900')(props),
      color: mode('palette.text', 'white')(props),
      fontFamily: 'body',
      fontSize: 'md',
      lineHeight: 'base'
    },
    '*::placeholder': {
      color: mode('gray.500', 'whiteAlpha.400')(props)
    }
  })
}
