import React from 'react'
import PropTypes from 'prop-types'

import { useTable } from './TableProvider'

import { Row } from '~/components/Grid'

function TableHeadRow (props) {
  const { children, ...restProps } = props

  const { spacing, rowMinHeight } = useTable()

  return (
    <Row
      align={'center'}
      p={2}
      minH={rowMinHeight}
      spacing={spacing}
      {...restProps}>
      {children}
    </Row>
  )
}

TableHeadRow.propTypes = {
  children: PropTypes.node.isRequired
}

export default TableHeadRow
