import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import {
  Input,
  ImageCrop,
  NumberInput,
  Textarea,
  withForm
} from '~/components/FormElements'
import { Card, CardButtons, CardGrid, FieldStack } from '~/components/Card'

function ProviderCreate (props) {
  const { form, isLoading, isLoadingData } = props
  const { handleSubmit } = form

  return (
    <Card as={'form'} isLoading={isLoadingData} onSubmit={handleSubmit}>
      <CardGrid>
        <FieldStack>
          <FieldStack direction={'row'}>
            <Field
              name={'photo'}
              component={ImageCrop}
              label={'Photo'}
              isRequired={true}
            />
            <FieldStack flexGrow={'1'}>
              <Field
                name={'name'}
                component={Input}
                label={'Provider name'}
                placeholder={'Provider name'}
                isRequired={true}
              />
              <Field
                name={'inn'}
                component={NumberInput}
                label={'INN'}
                placeholder={'INN'}
                isRequired={true}
                maxLength={9}
              />
            </FieldStack>
          </FieldStack>
          <Field
            name={'cashback'}
            component={NumberInput}
            label={'Cashback'}
            placeholder={'Cashback'}
            isRequired={true}
          />
          <Field
            name={'minOrderPrice'}
            component={NumberInput}
            label={'Min order price'}
            placeholder={'Min order price'}
            isRequired={true}
          />
        </FieldStack>
        <FieldStack>
          <Field
            name={'description'}
            component={Textarea}
            label={'Description'}
            placeholder={'Description'}
            isRequired={true}
          />
          <Field
            name={'address'}
            component={Textarea}
            label={'Address'}
            placeholder={'Address'}
            isRequired={true}
          />
        </FieldStack>
      </CardGrid>

      <CardButtons isLoading={isLoading} />
    </Card>
  )
}

ProviderCreate.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingData: PropTypes.bool
}

export default withForm(ProviderCreate)
