import React from 'react'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'
import { Box, Stack } from '@chakra-ui/react'

import { Card, CardDescription, CardHeader, CardImage, CardTitle } from '~/components/Card'
import { DataDropdown } from '~/components/DataDropdown'
import { DetailSkeleton } from '~/components/Skeletons'

function ProviderDetail (props) {
  const { detail, onEdit, onDelete } = props
  const { data, isLoading } = detail

  const id = prop('id', data)
  const name = prop('name', data)
  const inn = prop('inn', data)
  const photo = path(['photo', 'path'], data)
  const cashback = prop('cashback', data)
  const description = prop('description', data)
  const address = prop('address', data)
  const minOrderPrice = prop('minOrderPrice', data)

  return (
    <DetailSkeleton isLoading={isLoading}>
      <Card>
        <CardHeader>
          <CardTitle>
            {name}
          </CardTitle>
          <DataDropdown
            onEdit={onEdit.bind(null, id)}
            onDelete={onDelete}
          />
        </CardHeader>

        <Stack direction={'row'} spacing={8}>
          <CardImage src={photo} />
          <Box flexGrow={'1'}>
            <Box>
              <CardDescription title={'INN'} value={inn} />
              <CardDescription title={'Cashback'} value={cashback} />
              <CardDescription title={'Min. order price'} value={minOrderPrice} />
              <CardDescription title={'Description'} value={description} />
              <CardDescription title={'Address'} value={address} />
            </Box>
          </Box>
        </Stack>
      </Card>
    </DetailSkeleton>
  )
}

ProviderDetail.propTypes = {
  detail: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default ProviderDetail
