import {
  BannerListContainer,
  BannerDetailContainer,
  BannerCreateContainer,
  BannerUpdateContainer
} from './containers'

import * as ROUTES from '~/constants/routes'
import { MainLayout } from '~/components/Layouts'

export default [{
  path: ROUTES.BANNER_LIST_PATH,
  layout: MainLayout,
  component: BannerListContainer,
  routes: [
    {
      path: ROUTES.BANNER_DETAIL_PATH,
      layout: MainLayout,
      component: BannerDetailContainer
    },
    {
      path: ROUTES.BANNER_CREATE_PATH,
      layout: MainLayout,
      component: BannerCreateContainer
    },
    {
      path: ROUTES.BANNER_UPDATE_PATH,
      layout: MainLayout,
      component: BannerUpdateContainer
    }
  ]
}]
