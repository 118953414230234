import {
  BrandListContainer,
  BrandDetailContainer,
  BrandCreateContainer,
  BrandUpdateContainer
} from './containers'

import * as ROUTES from '~/constants/routes'
import { MainLayout } from '~/components/Layouts'

export default [{
  path: ROUTES.BRAND_LIST_PATH,
  layout: MainLayout,
  component: BrandListContainer,
  routes: [
    {
      path: ROUTES.BRAND_DETAIL_PATH,
      layout: MainLayout,
      component: BrandDetailContainer
    },
    {
      path: ROUTES.BRAND_CREATE_PATH,
      layout: MainLayout,
      component: BrandCreateContainer
    },
    {
      path: ROUTES.BRAND_UPDATE_PATH,
      layout: MainLayout,
      component: BrandUpdateContainer
    }
  ]
}]
