import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import { withForm, Input } from '~/components/FormElements'
import { Card, CardButtons, CardGrid } from '~/components/Card'

function OrderCreate (props) {
  const { form, isLoading, isLoadingData } = props
  const { handleSubmit } = form

  return (
    <Card as={'form'} isLoading={isLoadingData} onSubmit={handleSubmit}>
      <CardGrid>
        <Field
          name={'name'}
          component={Input}
          label={'Region name'}
          placeholder={'Region name'}
          isRequired={true}
        />
      </CardGrid>

      <CardButtons isLoading={isLoading} />
    </Card>
  )
}

OrderCreate.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingData: PropTypes.bool
}

export default withForm(OrderCreate)
