import React from 'react'
import { useParams } from 'react-router-dom'

import { useRedirectEdit, useUserDetail, useUserDelete } from '../hooks'
import { UserDetail } from '../components/UserDetail'

import { useConfirm } from '~/hooks/app'
import { RouterNavBar } from '~/components/NavBar'

export default function UserDetailContainer () {
  const { id } = useParams()
  const detail = useUserDetail(id)
  const remove = useUserDelete(id)

  const onDelete = useConfirm({
    remove,
    alertParams: { title: 'Remove user' },
    redirectPath: 'back'
  })
  const onEdit = useRedirectEdit()

  return (
    <RouterNavBar title={'User detail'}>
      <UserDetail
        detail={detail}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </RouterNavBar>
  )
}
