import { sprintf } from 'sprintf-js'

import * as API from '~/constants/api'
import * as ROUTES from '~/constants/routes'
import { useCreate, useDetail, useList, useRemove, useUpdate } from '~/hooks/crud'
import { useGetSearchParams } from '~/hooks/urls'
import { useRedirect } from '~/hooks/app'

export function useRedirectDetail () {
  return useRedirect(ROUTES.ORDER_DETAIL_URL)
}

export function useRedirectEdit () {
  return useRedirect(ROUTES.ORDER_UPDATE_URL)
}

export function useOrderList () {
  const params = useGetSearchParams()
  return useList(API.ORDER_LIST, params)
}

export function useOrderDetail (id) {
  const api = sprintf(API.ORDER_DETAIL, id)
  return useDetail(api)
}

export function useOrderDelete (id) {
  const api = sprintf(API.ORDER_DELETE, id)
  return useRemove(api)
}

export function useOrderCreate () {
  return useCreate(API.ORDER_CREATE)
}

export function useOrderUpdate (id) {
  const api = sprintf(API.ORDER_UPDATE, id)
  return useUpdate(api)
}
