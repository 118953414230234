import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Flex, Heading, Stack, useColorModeValue } from '@chakra-ui/react'

import LoginForm from './LoginForm'

import { useAuth } from '~/contexts/AuthProvider'
import Container from '~/components/Container'

export default function SimpleCard () {
  const history = useHistory()
  const { isAuth, login } = useAuth()

  useEffect(() => {
    if (isAuth) {
      history.replace('/')
    }
  }, [])

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}>
      <Container>
        <Box
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          rounded={'lg'}
          maxW={'sm'}
          mx={'auto'}
          p={8}
          w={'full'}>
          <Stack spacing={10}>
            <Heading fontSize={'4xl'} textAlign={'center'}>Sign in</Heading>
            <LoginForm {...login} />
          </Stack>
        </Box>
      </Container>
    </Flex>
  )
}
