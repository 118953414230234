import React from 'react'
import { Flex } from '@chakra-ui/react'

export default function CardHeader (props) {
  return (
    <Flex
      align={'center'}
      justify={'space-between'}
      mb={10}
      {...props}
    />
  )
}
