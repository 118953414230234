import {
  OrderListContainer,
  OrderDetailContainer,
  OrderCreateContainer,
  OrderUpdateContainer
} from './containers'

import * as ROUTES from '~/constants/routes'
import { MainLayout } from '~/components/Layouts'

export default [{
  path: ROUTES.ORDER_LIST_PATH,
  layout: MainLayout,
  component: OrderListContainer,
  routes: [
    {
      path: ROUTES.ORDER_DETAIL_PATH,
      layout: MainLayout,
      component: OrderDetailContainer
    },
    {
      path: ROUTES.ORDER_CREATE_PATH,
      layout: MainLayout,
      component: OrderCreateContainer
    },
    {
      path: ROUTES.ORDER_UPDATE_PATH,
      layout: MainLayout,
      component: OrderUpdateContainer
    }
  ]
}]
