import { useLocation } from 'react-router-dom'
import { filter, isEmpty, mergeRight, not, pipe } from 'ramda'

import { parseParams, paramsToSearch } from './utils'

export default function useAppendParamsToUrl () {
  const { pathname, search } = useLocation()

  function appendParamsToUrl (appendParams) {
    const params = parseParams(search)
    const newParams = pipe(
      mergeRight(params),
      filter(pipe(isEmpty, not))
    )(appendParams)

    return `${pathname}?${paramsToSearch(newParams)}`
  }

  return appendParamsToUrl
}
