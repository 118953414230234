import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react'

// import Container from '~/components/Container'

function MainLayout ({ children }) {
  return (
    <Box minH={'100vh'}>
      {children}
    </Box>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default MainLayout
