import React from 'react'
import { useHistory } from 'react-router-dom'

import { brandCreateSerializer } from '../serializers'
import { useBrandCreate } from '../hooks'
import { BrandCreate } from '../components/BrandCreate'

import useToast, { SUCCESS_CREATE_PARAMS } from '~/hooks/useToast'
import { RouterNavBar } from '~/components/NavBar'

export default function BrandCreateContainer () {
  const history = useHistory()
  const toast = useToast()
  const { create, isLoading } = useBrandCreate()

  function onSubmit (formValues) {
    return create(brandCreateSerializer(formValues))
      .then(() => toast(SUCCESS_CREATE_PARAMS))
      .then(() => history.goBack())
  }

  return (
    <RouterNavBar title={'Brand create'}>
      <BrandCreate
        onSubmit={onSubmit}
        isLoading={isLoading}
      />
    </RouterNavBar>
  )
}
