import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import Cropper from 'react-easy-crop'
import { Box, FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'

import useUploadReducer from '../useUploadReducer'

import useImageCrop from './useImageCrop'
import CropImage from './CropImage'

import Modal from '~/components/Modal'

const CropContext = createContext(null)

function CropProvider (props) {
  const { input, meta, aspect, isRequired, label, ...restProps } = props

  const [state, dispatch] = useUploadReducer()

  const {
    imageSrc,
    crop,
    zoom,
    onCropChange: setCrop,
    onZoomChange: setZoom,
    onCropComplete,
    onUploadCroppedImage,
    onFileChange,
    isOpenPreview,
    onClosePreview
  } = useImageCrop({ input, dispatch })

  const { name, value } = input
  const { submitError, touched } = meta
  const isInvalid = !!(submitError && touched)

  return (
    <CropContext.Provider value={{ state }}>
      <Box>
        <FormControl id={name} isRequired={isRequired} isInvalid={isInvalid}>
          <FormLabel
            as={'label'}
            display={'block'}
            fontWeight={'medium'}
            mb={2}>
            {label}
          </FormLabel>

          <CropImage
            input={{ name, value }}
            isInvalid={isInvalid}
            onFileChange={onFileChange}
            {...restProps}
          />

          <FormErrorMessage>{submitError}</FormErrorMessage>
        </FormControl>
      </Box>

      <Modal
        title={'Image preview'}
        isOpen={isOpenPreview}
        onClose={onClosePreview}
        onSubmit={onUploadCroppedImage}
        submitText={'Save'}
        size={'xl'}
        closeOnOverlayClick={false}>
        <Box pos={'relative'} h={'400px'}>
          {imageSrc && (
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          )}
        </Box>
      </Modal>
    </CropContext.Provider>
  )
}

export function useCrop () {
  return useContext(CropContext)
}

CropProvider.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  aspect: PropTypes.number,
  isRequired: PropTypes.bool,
  label: PropTypes.string
}

CropProvider.defaultProps = {
  aspect: 1
}

export default CropProvider
