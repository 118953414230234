import React from 'react'
import { css, Global } from '@emotion/react'

export default function FontStyles () {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-weight: 400;
          font-display: fallback;
          src: url('/fonts/Gilroy-Regular.woff') format('woff');
        }
        @font-face {
          font-family: Gilroy;
          font-style: italic;
          font-weight: 400;
          font-display: fallback;
          src: url('/fonts/Gilroy-RegularItalic.woff') format('woff');
        }

        @font-face {
          font-family: Gilroy;
          font-style: normal;
          font-weight: 500;
          font-display: fallback;
          src: url('/fonts/Gilroy-Medium.woff') format('woff');
        }
        @font-face {
          font-family: Gilroy;
          font-style: italic;
          font-weight: 500;
          font-display: fallback;
          src: url('/fonts/Gilroy-MediumItalic.woff') format('woff');
        }

        @font-face {
          font-family: Gilroy;
          font-style: normal;
          font-weight: 600;
          font-display: fallback;
          src: url('/fonts/Gilroy-SemiBold.woff') format('woff');
        }
        @font-face {
          font-family: Gilroy;
          font-style: italic;
          font-weight: 600;
          font-display: fallback;
          src: url('/fonts/Gilroy-SemiBoldItalic.woff') format('woff');
        }

        @font-face {
          font-family: Gilroy;
          font-style: normal;
          font-weight: 700;
          font-display: fallback;
          src: url('/fonts/Gilroy-Bold.woff') format('woff');
        }
        @font-face {
          font-family: Gilroy;
          font-style: italic;
          font-weight: 700;
          font-display: fallback;
          src: url('/fonts/Gilroy-BoldItalic.woff') format('woff');
        }
      `}
    />
  )
}
