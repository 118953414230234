import React from 'react'
import { useColorMode, useColorModeValue, IconButton } from '@chakra-ui/react'
import { Moon, Sun } from 'react-feather'

export function ColorModeSwitcher (props) {
  const { toggleColorMode } = useColorMode()
  const text = useColorModeValue('dark', 'light')
  const SwitchIcon = useColorModeValue(Moon, Sun)

  return (
    <IconButton
      aria-label={`Switch to ${text} mode`}
      icon={<SwitchIcon />}
      color={'current'}
      colorScheme={'gray'}
      fontSize={'lg'}
      onClick={toggleColorMode}
      variant={'ghost'}
      {...props}
    />
  )
}
