import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Box, Heading } from '@chakra-ui/react'

import { TableContext } from '../Table/TableContext'
import TableActions from '../Table/TableActions'

import PhotoGrid from './PhotoGrid'

import Paper from '~/components/Paper'

function PhotoGridProvider (props) {
  const { children, title, list, isLoading, columns, spacing } = props

  const tableProps = {
    columns,
    spacing,
    list,
    isLoading,
    isCreatable: true
  }

  return (
    <TableContext.Provider value={tableProps}>
      <Paper px={8} py={6}>
        <Heading fontSize={'2xl'} fontWeight={'semibold'} mb={6}>
          {title}
        </Heading>

        <TableActions />

        <Box mt={10}>
          <PhotoGrid>{children}</PhotoGrid>
        </Box>
      </Paper>
    </TableContext.Provider>
  )
}

export function usePhotoGrid () {
  return useContext(TableContext)
}

PhotoGridProvider.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  list: PropTypes.array,
  isLoading: PropTypes.bool,
  columns: PropTypes.number,
  spacing: PropTypes.number
}

PhotoGridProvider.defaultProps = {
  columns: 4,
  spacing: 6
}

export default PhotoGridProvider
