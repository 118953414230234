import { useTheme, useColorModeValue as mode } from '@chakra-ui/react'

export default function useSelectTheme () {
  const { colors, radii } = useTheme()

  const primary = mode(colors.primary[500], colors.gray[600])
  const primary25 = mode(colors.primary[200], colors.gray[200])
  const primary50 = mode(colors.primary[300], colors.gray[300])
  const primary75 = mode(colors.primary[400], colors.gray[400])

  const neutral0 = mode(colors.white, colors.gray[800])
  const neutral5 = mode(colors.gray[50], colors.white)
  const neutral10 = mode(colors.gray[100], colors.whiteAlpha[50])
  const neutral20 = mode(colors.gray[200], colors.whiteAlpha[100])
  const neutral30 = mode(colors.gray[300], colors.whiteAlpha[200])
  const neutral40 = mode(colors.gray[400], colors.whiteAlpha[300])
  const neutral50 = mode(colors.gray[500], colors.whiteAlpha[400])
  const neutral60 = mode(colors.gray[600], colors.whiteAlpha[500])
  const neutral70 = mode(colors.gray[700], colors.whiteAlpha[600])
  const neutral80 = mode(colors.gray[800], colors.whiteAlpha[700])
  const neutral90 = mode(colors.gray[900], colors.whiteAlpha[800])

  return function getTheme (selectTheme) {
    return {
      ...selectTheme,
      borderRadius: radii.md,
      colors: {
        ...selectTheme.colors,
        danger: colors.red[500],
        dangerLight: colors.red[300],

        primary,
        primary25,
        primary50,
        primary75,

        neutral0,
        neutral5,
        neutral10,
        neutral20,
        neutral30,
        neutral40,
        neutral50,
        neutral60,
        neutral70,
        neutral80,
        neutral90
      },
      spacing: {
        ...selectTheme.spacing,
        controlHeight: 40
      }
    }
  }
}
