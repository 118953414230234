import React from 'react'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCol
} from '~/components/Table'

function TokenList (props) {
  const { list } = props
  const { results, isLoading } = list

  return (
    <Table
      title={'Tokens'}
      list={results}
      isLoading={isLoading}>
      <TableHead>
        <TableRow>
          <TableCol span={12}>Provider</TableCol>
          <TableCol span={12}>Key</TableCol>
        </TableRow>
      </TableHead>
      <TableBody>
        {results.map(item => {
          const id = prop('id', item)
          const provider = path(['provider', 'name'], item)
          const key = prop('key', item)

          return (
            <TableRow key={id}>
              <TableCol span={12}>{provider}</TableCol>
              <TableCol span={12}>{key}</TableCol>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

TokenList.propTypes = {
  list: PropTypes.object.isRequired
}

export default TokenList
