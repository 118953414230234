import {
  ProductListContainer,
  ProductDetailContainer,
  ProductCreateContainer,
  ProductUpdateContainer
} from './containers'

import * as ROUTES from '~/constants/routes'
import { MainLayout } from '~/components/Layouts'

export default [{
  path: ROUTES.PRODUCT_LIST_PATH,
  layout: MainLayout,
  component: ProductListContainer,
  routes: [
    {
      path: ROUTES.PRODUCT_DETAIL_PATH,
      layout: MainLayout,
      component: ProductDetailContainer
    },
    {
      path: ROUTES.PRODUCT_CREATE_PATH,
      layout: MainLayout,
      component: ProductCreateContainer
    },
    {
      path: ROUTES.PRODUCT_UPDATE_PATH,
      layout: MainLayout,
      component: ProductUpdateContainer
    }
  ]
}]
