import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { bannerCreateSerializer } from '../serializers'
import { useBannerDetail, useBannerUpdate } from '../hooks'
import { BannerCreate } from '../components/BannerCreate'

import { RouterNavBar } from '~/components/NavBar'
import useToast, { SUCCESS_UPDATE_PARAMS } from '~/hooks/useToast'

export default function BannerUpdateContainer () {
  const { id } = useParams()
  const history = useHistory()
  const toast = useToast()
  const { data, isLoading: isLoadingData } = useBannerDetail(id)
  const { update, isLoading } = useBannerUpdate(id)

  function onSubmit (formValues) {
    return update(bannerCreateSerializer(formValues))
      .then(() => toast(SUCCESS_UPDATE_PARAMS))
      .then(() => history.goBack())
  }

  return (
    <RouterNavBar title={'Banner edit'}>
      <BannerCreate
        onSubmit={onSubmit}
        isLoading={isLoading}
        isLoadingData={isLoadingData}
        initialValues={data}
      />
    </RouterNavBar>
  )
}
