import React from 'react'
import { useHistory } from 'react-router-dom'

import { categoryCreateSerializer } from '../serializers'
import { useCategoryCreate } from '../hooks'
import { CategoryCreate } from '../components/CategoryCreate'

import useToast, { SUCCESS_CREATE_PARAMS } from '~/hooks/useToast'
import { RouterNavBar } from '~/components/NavBar'

export default function CategoryCreateContainer () {
  const history = useHistory()
  const toast = useToast()
  const { create, isLoading } = useCategoryCreate()

  function onSubmit (formValues) {
    return create(categoryCreateSerializer(formValues))
      .then(() => toast(SUCCESS_CREATE_PARAMS))
      .then(() => history.goBack())
  }

  return (
    <RouterNavBar title={'Category create'}>
      <CategoryCreate
        onSubmit={onSubmit}
        isLoading={isLoading}
      />
    </RouterNavBar>
  )
}
