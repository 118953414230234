import { defaultTo, equals, pipe, toLower } from 'ramda'

import useGetSearchParam from './useGetSearchParam'

export default function useGetBoolean (key) {
  const param = useGetSearchParam(key)

  return pipe(
    defaultTo('false'),
    toLower,
    equals('true')
  )(param)
}
