import {
  map,
  prop,
  is,
  mapObjIndexed,
  fromPairs,
  pipe,
  length,
  values,
  filter
} from 'ramda'

import useModal from './useModal'

import toCamelCase from '~/utils/toCamelCase'
import { useReplaceParams, usePickSearchParams } from '~/hooks/urls'

function valuesToUrlParams (values) {
  const mapper = value => is(Object, value) ? prop('id', value) : value
  return map(mapper, values)
}

function urlParamsToValues (values) {
  const mapper = value => isNaN(parseInt(value)) ? value : Number(value)
  return mapObjIndexed(mapper, values)
}

export default function useFilter (params) {
  const { fields, mapValues = valuesToUrlParams, mapUrls = urlParamsToValues } = params

  const urlInitValues = usePickSearchParams(fields, toCamelCase)
  const replaceParams = useReplaceParams()

  const key = 'filter'
  const modal = useModal({ key })

  const initialValues = mapUrls(urlInitValues)
  const count = pipe(
    values,
    filter(Boolean),
    length
  )(initialValues)

  function onSubmit (values) {
    replaceParams({ ...mapValues(values), [key]: false })
  }

  function onClear () {
    const filterValuesToNull = fields.map(item => [item, null])
    const values = fromPairs(filterValuesToNull)
    replaceParams({ ...values, [key]: false })
  }

  return { ...modal, count, initialValues, onClear, onSubmit }
}
