import { extendTheme } from '@chakra-ui/react'

import colors from './colors'
import components from './components'
import styles from './styles'

const fonts = {
  body: 'Gilroy, sans-serif',
  heading: 'Gilroy, sans-serif'
}

export default extendTheme({
  colors,
  components,
  fonts,
  styles
})
