import React from 'react'
import PropTypes from 'prop-types'
import { pipe, prop, ifElse, is, reduce, takeLast, omit, defaultTo } from 'ramda'
import isEqual from 'react-fast-compare'
import { FORM_ERROR } from 'final-form'
import arrayMutators from 'final-form-arrays'
import { Form as FinalForm } from 'react-final-form'

import toCamelCase from '~/utils/toCamelCase'
import useToast from '~/hooks/useToast'

const NON_FIELD_ERROR = 'nonFieldErrors'

function getDefaultNonFieldErrors (error) {
  return error ? { [NON_FIELD_ERROR]: [error] } : {}
}

function Form ({ onSubmit, ...props }) {
  const toast = useToast()

  function handleSubmit (data) {
    return onSubmit(data)
      .catch(e => {
        const fieldErrors = e
        if (e instanceof Error) {
          throw e
        }

        const formedErrorData = pipe(
          prop('detail'),
          ifElse(
            is(Object),
            reduce((acc, value) => {
              const name = pipe(prop('loc'), takeLast(1))(value)
              acc[name] = prop('msg', value)
              return toCamelCase(acc)
            }, {}),
            getDefaultNonFieldErrors
          )
        )(fieldErrors)

        const nonFieldError = prop(NON_FIELD_ERROR, formedErrorData)
        const nonFieldErrorMessage = defaultTo('Unable to connect to server', nonFieldError)
        const restFieldErrors = omit([NON_FIELD_ERROR], formedErrorData)

        if (nonFieldError) {
          toast({
            title: 'Error',
            description: nonFieldErrorMessage,
            status: 'error'
          })
        }

        return {
          ...restFieldErrors,
          [FORM_ERROR]: nonFieldError
        }
      })
  }

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      onSubmit={handleSubmit}
    />
  )
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

export default React.memo(Form, isEqual)
