import React from 'react'
import { useParams } from 'react-router-dom'

import { useRedirectEdit, useRegionDetail, useRegionDelete } from '../hooks'
import { RegionDetail } from '../components/RegionDetail'

import { useConfirm } from '~/hooks/app'
import { RouterNavBar } from '~/components/NavBar'

export default function RegionDetailContainer () {
  const { id } = useParams()
  const detail = useRegionDetail(id)
  const remove = useRegionDelete(id)

  const onDelete = useConfirm({
    remove,
    alertParams: { title: 'Remove region' },
    redirectPath: 'back'
  })
  const onEdit = useRedirectEdit()

  return (
    <RouterNavBar title={'Region detail'}>
      <RegionDetail
        detail={detail}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </RouterNavBar>
  )
}
