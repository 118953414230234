import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { YMaps, Map, Placemark } from 'react-yandex-maps'
import { Box } from '@chakra-ui/react'

import FormControl from '../FormControl'

function getMapCenter (value = []) {
  const [lat, lon] = value
  const defaultCenter = ['41.312187', '69.311218']

  if (lat && lon) return value
  return defaultCenter
}

function getAddress (coords) {
  return ''
}

function YandexMaps (props) {
  const { input, label } = props
  const { name, value } = input

  const [map, setMap] = useState(null)
  const [address, setAddress] = useState(null)

  const defaultState = {
    center: getMapCenter(value),
    zoom: 14
  }

  return (
    <Box>
      <FormControl id={name} label={label}>
        <YMaps preload={true}>
          <Map
            defaultState={defaultState}
            height={'316px'}
            width={'100%'}
            modules={['geocode']}
            onLoad={setMap}
            onClick={event => {
              const coords = event.get('coords')
              const address = getAddress(coords, map)
              setAddress(address)
              input.onChange(coords)
            }}>
            <Placemark
              geometry={value}
              properties={{
                iconCaption: address
              }}
            />
          </Map>
        </YMaps>
      </FormControl>
    </Box>
  )
}

YandexMaps.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string
}

export default YandexMaps
