import React from 'react'

import {
  useRedirectDetail,
  useRedirectEdit,
  useBrandList
} from '../hooks'
import { BrandList } from '../components/BrandList'

import { NAV_KEYS } from '~/constants/nav'
import { AppBar } from '~/components/AppBar'

export default function BrandListContainer () {
  const list = useBrandList()
  const toDetail = useRedirectDetail()
  const onEdit = useRedirectEdit()

  return (
    <AppBar activeNav={NAV_KEYS.BRANDS}>
      <BrandList
        list={list}
        toDetail={toDetail}
        onEdit={onEdit}
      />
    </AppBar>
  )
}
