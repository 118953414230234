import React from 'react'

import {
  getOptions,
  getOption,
  defaultGetText,
  defaultGetValue,
  searchFieldPropTypes
} from './utils'
import SearchFieldBase from './SearchFieldBase'

function SearchField (props) {
  const { api, detailApi, params, itemText, ...restProps } = props

  return (
    <SearchFieldBase
      getText={defaultGetText(itemText)}
      getValue={defaultGetValue(['id'])}
      getOptions={search => getOptions({ api, params, search })}
      getOption={getOption(api, detailApi)}
      {...restProps}
    />
  )
}

SearchField.propTypes = {
  ...searchFieldPropTypes
}

SearchField.defaultProps = {
  itemText: ['name']
}

export default SearchField
