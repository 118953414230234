import React from 'react'

import {
  useRedirectDetail,
  useRedirectEdit,
  useUserList
} from '../hooks'
import { UserList } from '../components/UserList'

import { NAV_KEYS } from '~/constants/nav'
import { AppBar } from '~/components/AppBar'

export default function UserListContainer () {
  const list = useUserList()
  const toDetail = useRedirectDetail()
  const onEdit = useRedirectEdit()

  return (
    <AppBar activeNav={NAV_KEYS.USERS}>
      <UserList
        list={list}
        toDetail={toDetail}
        onEdit={onEdit}
      />
    </AppBar>
  )
}
