import { theme } from '@chakra-ui/react'

export default {
  primary: {
    ...theme.colors.gray,
    500: '#2934D0',
    600: '#252FBB',
    700: '#1F279C',
    800: '#191F7D',
    900: '#141966'
  },
  palette: {
    text: '#172017'
  }
}
