import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import * as API from '~/constants/api'
import { withForm, SearchField } from '~/components/FormElements'
import { Card, CardButtons, CardGrid } from '~/components/Card'

function TokenCreate (props) {
  const { form, isLoading } = props
  const { handleSubmit } = form

  return (
    <Card as={'form'} onSubmit={handleSubmit}>
      <CardGrid>
        <Field
          name={'provider'}
          component={SearchField}
          api={API.PROVIDER_LIST}
          label={'Provider'}
          isRequired={true}
        />
      </CardGrid>

      <CardButtons isLoading={isLoading} />
    </Card>
  )
}

TokenCreate.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default withForm(TokenCreate)
