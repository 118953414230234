import { useLocation } from 'react-router-dom'
import { pipe, prop } from 'ramda'

import { parseParams } from './utils'

export default function getGetSearchParam (key) {
  const location = useLocation()

  return pipe(
    prop('search'),
    decodeURIComponent,
    parseParams,
    prop(key)
  )(location)
}
