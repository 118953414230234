import React from 'react'
import { useHistory } from 'react-router-dom'

import { orderCreateSerializer } from '../serializers'
import { useOrderCreate } from '../hooks'
import { OrderCreate } from '../components/OrderCreate'

import useToast, { SUCCESS_CREATE_PARAMS } from '~/hooks/useToast'
import { RouterNavBar } from '~/components/NavBar'

export default function OrderCreateContainer () {
  const history = useHistory()
  const toast = useToast()
  const { create, isLoading } = useOrderCreate()

  function onSubmit (formValues) {
    return create(orderCreateSerializer(formValues))
      .then(() => toast(SUCCESS_CREATE_PARAMS))
      .then(() => history.goBack())
  }

  return (
    <RouterNavBar title={'Order create'}>
      <OrderCreate
        onSubmit={onSubmit}
        isLoading={isLoading}
      />
    </RouterNavBar>
  )
}
