import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react'

function CardData (props) {
  const { label, children } = props

  return (
    <Box>
      <Box color={'gray.500'} fontWeight={'medium'}>
        {label}
      </Box>
      <Box>{children}</Box>
    </Box>
  )
}

CardData.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node
}

export default CardData
