import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react'

function Col (props) {
  const { span } = props

  const maxColumnsCount = 24
  const flexBasisValue = (span / maxColumnsCount) * 100
  const flexBasis = span ? `${flexBasisValue}%` : 'unset'

  return (
    <Box
      flexGrow={'1'}
      flexBasis={flexBasis}
      {...props}
    />
  )
}

Col.propTypes = {
  span: PropTypes.number
}

export default Col
