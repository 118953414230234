import React from 'react'
import PropTypes from 'prop-types'
import { range } from 'ramda'
import { Box, Skeleton as ChakraSkeleton } from '@chakra-ui/react'

import { useSkeletonColors } from './useSkeletonColors'

import { TableHead, TableRow, TableCol } from '~/components/Table'

function Skeleton (props) {
  const colors = useSkeletonColors()

  return (
    <ChakraSkeleton h={5} w={'full'} {...colors.light} {...props} />
  )
}

function SkeletonDark (props) {
  const colors = useSkeletonColors()

  return (
    <Skeleton w={'75%'} {...colors.dark} {...props} />
  )
}

const TableSkeleton = props => {
  const { count } = props
  const list = range(0, count)

  return (
    <Box {...props}>
      <TableHead>
        <TableRow>
          <TableCol span={9}>
            <SkeletonDark />
          </TableCol>
          <TableCol span={3}>
            <SkeletonDark />
          </TableCol>
          <TableCol span={3}>
            <SkeletonDark />
          </TableCol>
          <TableCol span={3}>
            <SkeletonDark />
          </TableCol>
          <TableCol span={3}>
            <SkeletonDark />
          </TableCol>
          <TableCol span={3}>
            <SkeletonDark />
          </TableCol>
        </TableRow>
      </TableHead>
      {list.map(item => {
        return (
          <TableRow key={item}>
            <TableCol span={9}>
              <Skeleton />
            </TableCol>
            <TableCol span={3}>
              <Skeleton />
            </TableCol>
            <TableCol span={3}>
              <Skeleton />
            </TableCol>
            <TableCol span={3}>
              <Skeleton />
            </TableCol>
            <TableCol span={3}>
              <Skeleton />
            </TableCol>
            <TableCol span={3}>
              <Skeleton />
            </TableCol>
          </TableRow>
        )
      })}
    </Box>
  )
}

TableSkeleton.propTypes = {
  count: PropTypes.number
}

TableSkeleton.defaultProps = {
  count: 8
}

export default TableSkeleton
