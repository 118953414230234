import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import * as API from '~/constants/api'
import {
  Input,
  ImageCrop,
  NumberInput,
  SearchField,
  Textarea,
  withForm
} from '~/components/FormElements'
import { Card, CardButtons, CardGrid, FieldStack } from '~/components/Card'

function ProductCreate (props) {
  const { form, isLoading, isLoadingData } = props
  const { handleSubmit } = form

  return (
    <Card as={'form'} isLoading={isLoadingData} onSubmit={handleSubmit}>
      <CardGrid>
        <FieldStack>
          <FieldStack direction={'row'}>
            <Field
              name={'photo'}
              component={ImageCrop}
              label={'Photo'}
              isRequired={true}
            />
            <FieldStack flexGrow={'1'}>
              <Field
                name={'name'}
                component={Input}
                label={'Product name'}
                placeholder={'Product name'}
                isRequired={true}
              />
              <Field
                name={'category'}
                component={SearchField}
                api={API.CATEGORY_LIST}
                label={'Category'}
                isRequired={true}
              />
            </FieldStack>
          </FieldStack>
          <Field
            name={'brand'}
            component={SearchField}
            api={API.BRAND_LIST}
            label={'Brand'}
            isRequired={true}
          />
          <Field
            name={'provider'}
            component={SearchField}
            api={API.PROVIDER_LIST}
            label={'Provider'}
            isRequired={true}
          />
          <Field
            name={'measurement'}
            component={SearchField}
            api={API.MEASUREMENT_LIST}
            label={'Measurement'}
            isRequired={true}
          />
        </FieldStack>
        <FieldStack>
          <Field
            name={'barcode'}
            component={Input}
            label={'Barcode'}
          />
          <Field
            name={'price'}
            component={NumberInput}
            label={'Price'}
            placeholder={'Product price'}
            isRequired={true}
          />
          <Field
            name={'cashback'}
            component={NumberInput}
            label={'Cashback'}
          />
          <Field
            name={'description'}
            component={Textarea}
            label={'Description'}
            placeholder={'Description'}
            isRequired={true}
          />
        </FieldStack>
      </CardGrid>

      <CardButtons isLoading={isLoading} />
    </Card>
  )
}

ProductCreate.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingData: PropTypes.bool
}

export default withForm(ProductCreate)
