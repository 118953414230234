import React from 'react'
import { Image as ImageIcon } from 'react-feather'
import { Center, CircularProgress, Icon, useColorModeValue as mode } from '@chakra-ui/react'

import { useCrop } from './CropProvider'

export default function CropImagePlaceholder () {
  const { state } = useCrop()
  const { isLoading, progress } = state

  return (
    <Center h={'full'}>
      <CircularProgress
        color={mode('primary.500', 'gray.500')}
        display={isLoading ? 'block' : 'none'}
        trackColor={mode('gray.100', 'gray.700')}
        value={progress}
      />
      <Icon
        as={ImageIcon}
        boxSize={7}
        color={mode('gray.500', 'gray.400')}
        display={isLoading ? 'none' : 'block'}
      />
    </Center>
  )
}
