import { mode } from '@chakra-ui/theme-tools'

export default {
  baseStyle: props => ({
    dialog: {
      bg: mode('white', 'gray.800')(props)
    },
    body: {
      borderTopWidth: 1,
      borderColor: mode('gray.200', 'gray.700')(props)
    }
  })
}
