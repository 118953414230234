const API_PROTOCOL = 'https'
const API_DOMAIN_URL = 'newdev.set.uz'
const API_VERSION = 'api/v1'
export const API_BASE_URL = `${API_PROTOCOL}://${API_DOMAIN_URL}/${API_VERSION}`

const USER = 'user'
const USER_OPTIONS = `/${USER}/%d/`
export const LOGIN = `/${USER}/login/`
export const ME = `/${USER}/me/`
export const USER_LIST = `/${USER}/`
export const USER_CREATE = `/${USER}/`
export const USER_DETAIL = `/${USER}/detail/%d/`
export const USER_DELETE = USER_OPTIONS
export const USER_UPDATE = USER_OPTIONS

const FILE = 'file'
export const FILE_CREATE = `/${FILE}/`

const BRAND = 'brand'
const BRAND_OPTIONS = `/${BRAND}/%d/`
export const BRAND_LIST = `/${BRAND}/`
export const BRAND_CREATE = `/${BRAND}/`
export const BRAND_DETAIL = `/${BRAND}/detail/%d/`
export const BRAND_DELETE = BRAND_OPTIONS
export const BRAND_UPDATE = BRAND_OPTIONS

const PROVIDER = 'provider'
const PROVIDER_OPTIONS = `/${PROVIDER}/%d/`
export const PROVIDER_LIST = `/${PROVIDER}/`
export const PROVIDER_CREATE = `/${PROVIDER}/`
export const PROVIDER_DETAIL = `/${PROVIDER}/detail/%d/`
export const PROVIDER_DELETE = PROVIDER_OPTIONS
export const PROVIDER_UPDATE = PROVIDER_OPTIONS

const CATEGORY = 'category'
const CATEGORY_OPTIONS = `/${CATEGORY}/%d/`
export const CATEGORY_LIST = `/${CATEGORY}/`
export const CATEGORY_CREATE = `/${CATEGORY}/`
export const CATEGORY_DETAIL = `/${CATEGORY}/detail/%d/`
export const CATEGORY_DELETE = CATEGORY_OPTIONS
export const CATEGORY_UPDATE = CATEGORY_OPTIONS

const PRODUCT = 'product'
const PRODUCT_OPTIONS = `/${PRODUCT}/%d/`
export const PRODUCT_LIST = `/${PRODUCT}/`
export const PRODUCT_CREATE = `/${PRODUCT}/`
export const PRODUCT_DETAIL = `/${PRODUCT}/detail/%d/`
export const PRODUCT_DELETE = PRODUCT_OPTIONS
export const PRODUCT_UPDATE = PRODUCT_OPTIONS

const ORDER = 'order'
const ORDER_OPTIONS = `/${ORDER}/%d/`
export const ORDER_LIST = `/${ORDER}/`
export const ORDER_CREATE = `/${ORDER}/`
export const ORDER_DETAIL = `/${ORDER}/detail/%d/`
export const ORDER_DELETE = ORDER_OPTIONS
export const ORDER_UPDATE = ORDER_OPTIONS

const REGION = 'region'
const REGION_OPTIONS = `/${REGION}/%d/`
export const REGION_LIST = `/${REGION}/`
export const REGION_CREATE = `/${REGION}/`
export const REGION_DETAIL = `/${REGION}/detail/%d/`
export const REGION_DELETE = REGION_OPTIONS
export const REGION_UPDATE = REGION_OPTIONS

const BANNER = 'banner'
const BANNER_OPTIONS = `/${BANNER}/%d/`
export const BANNER_LIST = `/${BANNER}/`
export const BANNER_CREATE = `/${BANNER}/`
export const BANNER_DETAIL = `/${BANNER}/detail/%d/`
export const BANNER_DELETE = BANNER_OPTIONS
export const BANNER_UPDATE = BANNER_OPTIONS

const MEASUREMENT = 'measurement'
const MEASUREMENT_OPTIONS = `/${MEASUREMENT}/%d/`
export const MEASUREMENT_LIST = `/${MEASUREMENT}/`
export const MEASUREMENT_CREATE = `/${MEASUREMENT}/`
export const MEASUREMENT_DETAIL = `/${MEASUREMENT}/detail/%d/`
export const MEASUREMENT_DELETE = MEASUREMENT_OPTIONS
export const MEASUREMENT_UPDATE = MEASUREMENT_OPTIONS

const TOKEN = 'token'
export const TOKEN_LIST = `/${TOKEN}/`
export const TOKEN_CREATE = `/${TOKEN}/`
