import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { providerCreateSerializer } from '../serializers'
import { useProviderDetail, useProviderUpdate } from '../hooks'
import { ProviderCreate } from '../components/ProviderCreate'

import { RouterNavBar } from '~/components/NavBar'
import useToast, { SUCCESS_UPDATE_PARAMS } from '~/hooks/useToast'

export default function ProviderUpdateContainer () {
  const { id } = useParams()
  const history = useHistory()
  const toast = useToast()
  const { data, isLoading: isLoadingData } = useProviderDetail(id)
  const { update, isLoading } = useProviderUpdate(id)

  function onSubmit (formValues) {
    return update(providerCreateSerializer(formValues))
      .then(() => toast(SUCCESS_UPDATE_PARAMS))
      .then(() => history.goBack())
  }

  return (
    <RouterNavBar title={'Provider edit'}>
      <ProviderCreate
        onSubmit={onSubmit}
        isLoading={isLoading}
        isLoadingData={isLoadingData}
        initialValues={data}
      />
    </RouterNavBar>
  )
}
