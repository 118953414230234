import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { Phone, Lock } from 'react-feather'
import { Button, Stack, Icon } from '@chakra-ui/react'

import { Input, withForm } from '~/components/FormElements'

function LoginForm (props) {
  const { form, isLoading } = props
  const { handleSubmit } = form

  return (
    <Stack as={'form'} onSubmit={handleSubmit} spacing={10}>
      <Stack spacing={4}>
        <Field
          name={'phoneNumber'}
          component={Input}
          label={'Phone number'}
          placeholder={'Phone number'}
          leftElement={<Icon as={Phone} color={'gray.500'} />}
        />
        <Field
          name={'password'}
          component={Input}
          label={'Password'}
          placeholder={'Password'}
          type={'password'}
          leftElement={<Icon as={Lock} color={'gray.500'} />}
        />
      </Stack>
      <Button isLoading={isLoading} type={'submit'}>
        Sign in
      </Button>
    </Stack>
  )
}

LoginForm.propTypes = {
  form: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool
}

export default withForm(LoginForm)
