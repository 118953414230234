import React from 'react'
import { Box } from '@chakra-ui/react'

import LogoSvg from './LogoSvg'

import Link from '~/components/Link'

export default function Logo () {
  return (
    <Box
      as={Link}
      display={'block'}
      to={'/'}
      h={'40px'}
      w={'110px'}
      _focus={{ boxShadow: 'none' }}>
      <LogoSvg h={'full'} w={'full'} />
    </Box>
  )
}
