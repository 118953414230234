import React, { Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'react-fast-compare'
import { Box, Image, Input, useColorModeValue as mode } from '@chakra-ui/react'

import CropImagePlaceholder from './CropImagePlaceholder'

function CropImage (props) {
  const { input, isInvalid, onFileChange, ...restProps } = props

  const inputRef = useRef()

  function handleClick () {
    inputRef.current.click()
  }

  const errorColor = mode('red.500', 'red.300')
  const frameBorderColor = isInvalid ? errorColor : mode('gray.200', 'gray.700')
  const frameBorderColorHover = isInvalid ? errorColor : mode('gray.400', 'gray.600')

  return (
    <Fragment>
      <Input
        ref={inputRef}
        accept={'image/*'}
        display={'none'}
        name={name}
        type={'file'}
        isRequired={false}
        onChange={onFileChange}
      />
      <Box
        cursor={'pointer'}
        display={'block'}
        m={'unset'}
        h={'132px'}
        w={'132px'}
        {...restProps}
        onClick={handleClick}>
        <Box
          borderWidth={2}
          borderColor={frameBorderColor}
          rounded={'md'}
          h={'full'}
          w={'full'}
          p={1}
          transition={'all 200ms'}
          overflow={'hidden'}
          _hover={{ borderColor: frameBorderColorHover }}>
          {input.value.path
            ? (
              <Image
                boxSize={'full'}
                objectFit={'cover'}
                src={input.value.path}
                rounded={'inherit'}
              />
            )
            : <CropImagePlaceholder />}
        </Box>
      </Box>
    </Fragment>
  )
}

CropImage.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any
  }).isRequired,
  isInvalid: PropTypes.bool,
  onFileChange: PropTypes.func.isRequired
}

export default React.memo(CropImage, isEqual)
