import React from 'react'

import { useTokenList } from '../hooks'
import { TokenList } from '../components/TokenList'

import { NAV_KEYS } from '~/constants/nav'
import { AppBar } from '~/components/AppBar'

export default function TokenListContainer () {
  const list = useTokenList()

  return (
    <AppBar activeNav={NAV_KEYS.SETTINGS}>
      <TokenList list={list} />
    </AppBar>
  )
}
