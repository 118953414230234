import React from 'react'
import { useHistory } from 'react-router-dom'

import { useRegionCreate } from '../hooks'
import { RegionCreate } from '../components/RegionCreate'

import useToast, { SUCCESS_CREATE_PARAMS } from '~/hooks/useToast'
import { RouterNavBar } from '~/components/NavBar'

export default function RegionCreateContainer () {
  const history = useHistory()
  const toast = useToast()
  const { create, isLoading } = useRegionCreate()

  function onSubmit (formValues) {
    return create(formValues)
      .then(() => toast(SUCCESS_CREATE_PARAMS))
      .then(() => history.goBack())
  }

  return (
    <RouterNavBar title={'Region create'}>
      <RegionCreate
        onSubmit={onSubmit}
        isLoading={isLoading}
      />
    </RouterNavBar>
  )
}
