import Button from './Button'
import Checkbox from './Checkbox'
import Drawer from './Drawer'
import FormError from './FormError'
import Icon from './Icon'
import Input from './Input'
import Link from './Link'
import Menu from './Menu'
import NumberInput from './NumberInput'
import Table from './Table'
import Textarea from './Textarea'

export default {
  Button,
  Checkbox,
  Drawer,
  FormError,
  Icon,
  Input,
  Link,
  Menu,
  NumberInput,
  Table,
  Textarea
}
