import React from 'react'
import PropTypes from 'prop-types'

import { AuthProvider } from '~/contexts/AuthProvider'
import { AlertProvider } from '~/components/AlertDialog'

function BaseProvider ({ children }) {
  return (
    <AuthProvider>
      <AlertProvider>
        {children}
      </AlertProvider>
    </AuthProvider>
  )
}

BaseProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default BaseProvider
