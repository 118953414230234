import { sprintf } from 'sprintf-js'

import * as API from '~/constants/api'
import * as ROUTES from '~/constants/routes'
import { useCreate, useDetail, useList, useRemove, useUpdate } from '~/hooks/crud'
import { useGetSearchParams } from '~/hooks/urls'
import { useRedirect } from '~/hooks/app'

export function useRedirectDetail () {
  return useRedirect(ROUTES.BRAND_DETAIL_URL)
}

export function useRedirectEdit () {
  return useRedirect(ROUTES.BRAND_UPDATE_URL)
}

export function useBrandList () {
  const params = useGetSearchParams()
  return useList(API.BRAND_LIST, params)
}

export function useBrandDetail (id) {
  const api = sprintf(API.BRAND_DETAIL, id)
  return useDetail(api)
}

export function useBrandDelete (id) {
  const api = sprintf(API.BRAND_DELETE, id)
  return useRemove(api)
}

export function useBrandCreate () {
  return useCreate(API.BRAND_CREATE)
}

export function useBrandUpdate (id) {
  const api = sprintf(API.BRAND_UPDATE, id)
  return useUpdate(api)
}
