import React from 'react'
import { useHistory } from 'react-router-dom'

import { userCreateSerializer } from '../serializers'
import { useUserCreate } from '../hooks'
import { UserCreate } from '../components/UserCreate'

import useToast, { SUCCESS_CREATE_PARAMS } from '~/hooks/useToast'
import { RouterNavBar } from '~/components/NavBar'

export default function UserCreateContainer () {
  const history = useHistory()
  const toast = useToast()
  const { create, isLoading } = useUserCreate()

  function onSubmit (formValues) {
    return create(userCreateSerializer(formValues))
      .then(() => toast(SUCCESS_CREATE_PARAMS))
      .then(() => history.goBack())
  }

  return (
    <RouterNavBar title={'User create'}>
      <UserCreate
        onSubmit={onSubmit}
        isLoading={isLoading}
      />
    </RouterNavBar>
  )
}
