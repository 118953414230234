import { useColorModeValue as mode } from '@chakra-ui/react'

export function useSkeletonColors () {
  return {
    light: {
      startColor: mode('gray.200', 'gray.400'),
      endColor: mode('gray.400', 'gray.600')
    },
    dark: {
      startColor: mode('gray.500', 'gray.200'),
      endColor: mode('gray.700', 'gray.400')
    }
  }
}
