export default {
  baseStyle: {
    _hover: {
      textDecor: 'none'
    },
    _focus: {
      boxShadow: 'none'
    }
  }
}
