import Login from './Login'
import Dashboard from './Dashboard'
import Brands from './Brands'
import Providers from './Providers'
import Products from './Products'
import Orders from './Orders'
import Categories from './Categories'
import Users from './Users'
import * as Settings from './Settings'

export default [
  ...Login,
  ...Dashboard,
  ...Brands,
  ...Providers,
  ...Categories,
  ...Products,
  ...Orders,
  ...Users,

  ...Settings.Banners,
  ...Settings.Regions,
  ...Settings.Measurements,
  ...Settings.Tokens
]
