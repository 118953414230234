import {
  MeasurementListContainer,
  MeasurementDetailContainer,
  MeasurementCreateContainer,
  MeasurementUpdateContainer
} from './containers'

import * as ROUTES from '~/constants/routes'
import { MainLayout } from '~/components/Layouts'

export default [{
  path: ROUTES.MEASUREMENT_LIST_PATH,
  layout: MainLayout,
  component: MeasurementListContainer,
  routes: [
    {
      path: ROUTES.MEASUREMENT_DETAIL_PATH,
      layout: MainLayout,
      component: MeasurementDetailContainer
    },
    {
      path: ROUTES.MEASUREMENT_CREATE_PATH,
      layout: MainLayout,
      component: MeasurementCreateContainer
    },
    {
      path: ROUTES.MEASUREMENT_UPDATE_PATH,
      layout: MainLayout,
      component: MeasurementUpdateContainer
    }
  ]
}]
