import React from 'react'
import { Box, useColorModeValue as mode } from '@chakra-ui/react'

export default function PhotoGridCard (props) {
  return (
    <Box
      borderColor={mode('gray.200', 'gray.900')}
      borderWidth={1}
      cursor={'pointer'}
      rounded={'md'}
      transition={'box-shadow 200ms ease-in'}
      overflow={'hidden'}
      _hover={{ boxShadow: 'md' }}
      {...props}
    />
  )
}
