import React from 'react'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'

// import Link from '~/components/Link'
import {
  PhotoGrid,
  Grid,
  PhotoGridCard,
  PhotoGridImage,
  PhotoGridTitle
} from '~/components/PhotoGrid'

function BrandList (props) {
  const { list, toDetail } = props

  const { results, isLoading } = list

  return (
    <PhotoGrid
      title={'Brands'}
      list={results}
      isLoading={isLoading}>
      <Grid>
        {results.map(item => {
          const id = prop('id', item)
          const name = prop('name', item)
          const photo = path(['photo', 'path'], item)

          return (
            <PhotoGridCard key={id} onClick={toDetail.bind(null, id)}>
              <PhotoGridImage src={photo} />
              <PhotoGridTitle>{name}</PhotoGridTitle>
            </PhotoGridCard>
          )
        })}
      </Grid>
    </PhotoGrid>
  )
}

BrandList.propTypes = {
  list: PropTypes.object.isRequired,
  toDetail: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired
}

export default BrandList
