import {
  RegionListContainer,
  RegionDetailContainer,
  RegionCreateContainer,
  RegionUpdateContainer
} from './containers'

import * as ROUTES from '~/constants/routes'
import { MainLayout } from '~/components/Layouts'

export default [{
  path: ROUTES.REGION_LIST_PATH,
  layout: MainLayout,
  component: RegionListContainer,
  routes: [
    {
      path: ROUTES.REGION_DETAIL_PATH,
      layout: MainLayout,
      component: RegionDetailContainer
    },
    {
      path: ROUTES.REGION_CREATE_PATH,
      layout: MainLayout,
      component: RegionCreateContainer
    },
    {
      path: ROUTES.REGION_UPDATE_PATH,
      layout: MainLayout,
      component: RegionUpdateContainer
    }
  ]
}]
