import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Skeleton, SkeletonText } from '@chakra-ui/react'

import { Card, CardHeader } from '~/components/Card'

function DetailSkeleton (props) {
  const { children, isLoading } = props

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <Skeleton h={6} w={'200px'} />
        </CardHeader>
        <SkeletonText noOfLines={4} spacing={4} />
      </Card>
    )
  }

  return (
    <Fragment>
      {children}
    </Fragment>
  )
}

DetailSkeleton.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default DetailSkeleton
