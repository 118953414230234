import { path, prop } from 'ramda'

export const userCreateSerializer = values => {
  const location = prop('location', values)
  const [lat, lon] = location

  return {
    isActive: true,
    marketName: prop('marketName', values),
    inn: prop('inn', values),
    phoneNumber: prop('phoneNumber', values),
    regionId: path(['region', 'id'], values),
    address: prop('address', values),
    lat,
    lon
  }
}
