import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import * as API from '~/constants/api'
import { Input, NumberInput, Textarea, SearchField, YandexMaps, withForm } from '~/components/FormElements'
import { Card, CardButtons, CardGrid, FieldStack } from '~/components/Card'

function UserCreate (props) {
  const { form, isLoading, isLoadingData } = props
  const { handleSubmit } = form

  return (
    <Card as={'form'} isLoading={isLoadingData} onSubmit={handleSubmit}>
      <CardGrid>
        <FieldStack>
          <Field
            name={'marketName'}
            component={Input}
            label={'Region name'}
            placeholder={'Region name'}
            isRequired={true}
          />
          <Field
            name={'inn'}
            component={NumberInput}
            label={'INN'}
            placeholder={'INN'}
            isRequired={true}
            maxLength={9}
          />
          <Field
            name={'phoneNumber'}
            component={Input}
            label={'Phone number'}
            placeholder={'Phone number'}
            isDisabled={true}
          />
          <Field
            name={'region'}
            component={SearchField}
            api={API.REGION_LIST}
            label={'Region'}
            isRequired={true}
          />
          <Field
            name={'address'}
            component={Textarea}
            label={'Address'}
            placeholder={'Address'}
            isRequired={true}
          />
        </FieldStack>

        <FieldStack>
          <Field
            name={'location'}
            component={YandexMaps}
            label={'Location'}
          />
        </FieldStack>
      </CardGrid>

      <CardButtons isLoading={isLoading} />
    </Card>
  )
}

UserCreate.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingData: PropTypes.bool
}

export default withForm(UserCreate)
