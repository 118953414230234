import { filled } from '../variants/inputVariants'

export default {
  defaultProps: {
    colorScheme: 'primary',
    variant: 'filled'
  },
  variants: {
    filled: props => ({
      field: filled(props)
    })
  }
}
