import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { Link as ChakraLink } from '@chakra-ui/react'

const LinkForwarded = forwardRef(function Link (props, ref) {
  const { to, ...restProps } = props

  return (
    <ChakraLink
      as={RouterLink}
      to={to}
      ref={ref}
      _hover={{ textDecor: 'none' }}
      {...restProps}
    />
  )
})

LinkForwarded.propTypes = {
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired
}

export default LinkForwarded
