import React from 'react'
import { Box } from '@chakra-ui/react'

import DashboardFilter from './DashboardFilter'

import { Table } from '~/components/Table'

// eslint-disable-next-line react/prop-types
export default function Dashboard ({ filter }) {
  const filterForm = <DashboardFilter />

  return (
    <Box>
      <Table
        title={'Dashboard'}
        filter={filter}
        filterForm={filterForm}>
        <Box />
      </Table>
    </Box>
  )
}
