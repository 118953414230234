import React, { Children, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { useColorModeValue } from '@chakra-ui/react'

import { useTable } from './TableProvider'
import TableHeadRow from './TableHeadRow'

import { Row } from '~/components/Grid'

function TableRow (props) {
  const { children, isBody, onClick, ...restProps } = props

  const { spacing, rowMinHeight } = useTable()

  const paddings = { pl: 6, pr: 2, py: 2 }

  if (isBody) {
    const clickableProps = onClick
      ? {
        cursor: 'pointer',
        transition: 'all 200ms',
        _hover: {
          bg: useColorModeValue('gray.50', 'gray.700'),
          _after: {
            opacity: 0
          }
        }
      }
      : {}

    return (
      <Row
        align={'center'}
        minH={rowMinHeight}
        pos={'relative'}
        spacing={spacing}
        rounded={'md'}
        _notLast={{
          _after: {
            borderColor: useColorModeValue('gray.200', 'gray.700'),
            borderBottomWidth: 1,
            content: '""',
            position: 'absolute',
            left: '5px',
            right: '5px',
            bottom: '-1px',
            transition: 'inherit'
          }
        }}
        onClick={onClick}
        {...clickableProps}
        {...paddings}
        {...restProps}>
        {Children.map(children, (child, key) => {
          return cloneElement(child, { key })
        })}
      </Row>
    )
  }

  return (
    <TableHeadRow {...paddings}>
      {children}
    </TableHeadRow>
  )
}

TableRow.propTypes = {
  children: PropTypes.node.isRequired,
  isBody: PropTypes.bool,
  onClick: PropTypes.func
}

export default TableRow
