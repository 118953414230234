import React from 'react'
import PropTypes from 'prop-types'
import { find, path, pipe, prop, propEq, propOr } from 'ramda'
import { Box, Stack } from '@chakra-ui/react'

import { Card, CardDescription, CardHeader, CardImage, CardTitle } from '~/components/Card'
import { DataDropdown } from '~/components/DataDropdown'
import { DetailSkeleton } from '~/components/Skeletons'
import { numberFormat } from '~/utils/number'

function ProductDetail (props) {
  const { detail, onEdit, onDelete } = props
  const { data, isLoading } = detail

  const id = prop('id', data)
  const name = prop('name', data)
  const barcode = prop('barcode', data) || '-'
  const photo = pipe(
    propOr([], 'productImages'),
    find(propEq('isMain', true)),
    path(['photo', 'path'])
  )(data)
  const category = path(['category', 'name'], data)
  const brand = path(['brand', 'name'], data)
  const provider = path(['provider', 'name'], data)
  const measurement = path(['measurement', 'name'], data)

  const price = numberFormat(prop('price', data))
  const cashback = prop('cashback', data)
  const description = prop('description', data)

  return (
    <DetailSkeleton isLoading={isLoading}>
      <Card>
        <CardHeader>
          <CardTitle>
            {name}
          </CardTitle>
          <DataDropdown
            onEdit={onEdit.bind(null, id)}
            onDelete={onDelete}
          />
        </CardHeader>

        <Stack direction={'row'} spacing={8}>
          <CardImage src={photo} />
          <Box flexGrow={'1'}>
            <Box>
              <CardDescription title={'Barcode'} value={barcode} />
              <CardDescription title={'Category'} value={category} />
              <CardDescription title={'Brand'} value={brand} />
              <CardDescription title={'Provider'} value={provider} />
              <CardDescription title={'Measurement'} value={measurement} />
              <CardDescription title={'Price'} value={price} />
              <CardDescription title={'Cashback'} value={cashback} />
              <CardDescription title={'Description'} value={description} />
            </Box>
          </Box>
        </Stack>
      </Card>
    </DetailSkeleton>
  )
}

ProductDetail.propTypes = {
  detail: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default ProductDetail
