import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { Box, SimpleGrid } from '@chakra-ui/react'

import { usePhotoGrid } from './PhotoGridProvider'

import { PhotoGridSkeleton } from '~/components/Skeletons'

export function Grid (props) {
  const { columns, spacing } = usePhotoGrid()

  return (
    <SimpleGrid
      columns={columns}
      spacing={spacing}
      {...props}
    />
  )
}

function PhotoGrid ({ children }) {
  const { list, isLoading } = usePhotoGrid()

  if (isLoading) {
    return (
      <PhotoGridSkeleton />
    )
  }

  if (isEmpty(list)) {
    return (
      <Box>no data</Box>
    )
  }

  return (
    <Fragment>{children}</Fragment>
  )
}

PhotoGrid.propTypes = {
  children: PropTypes.node.isRequired
}

export default PhotoGrid
