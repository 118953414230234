import { DashboardContainer } from './containers'

import * as ROUTES from '~/constants/routes'
import { MainLayout } from '~/components/Layouts'

export default [{
  exact: true,
  path: ROUTES.ROOT_PATH,
  component: DashboardContainer,
  layout: MainLayout
}]
