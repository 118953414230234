import React from 'react'
import PropTypes from 'prop-types'
import { ChevronDown } from 'react-feather'
import { NavLink } from 'react-router-dom'
import {
  Box,
  Flex,
  Stack,
  Collapse,
  useDisclosure,
  useColorModeValue as mode
} from '@chakra-ui/react'

import Link from '~/components/Link'
import { Icon as ChakraIcon } from '~/components/Chakra'

function NavItem (props) {
  const { href, label, icon: Icon, children, isActive } = props

  const { isOpen, onToggle } = useDisclosure()

  const itemBgActive = mode('primary.500', 'gray.200')
  const itemBgHoverDefault = mode('gray.200', 'gray.700')
  const itemColorActive = mode('white', 'palette.text')

  const activeStyleProps = {
    bgColor: isActive ? itemBgActive : 'transparent',
    color: isActive ? itemColorActive : 'inherit',
    _hover: {
      bgColor: isActive ? itemBgActive : itemBgHoverDefault
    }
  }

  const styleProps = {
    cursor: 'pointer',
    fontWeight: 'medium',
    px: 3,
    py: 2,
    rounded: 'md',
    transition: 'all 200ms'
  }

  if (children) {
    const isOpenCollapse = isOpen || isActive

    return (
      <Stack spacing={0}>
        <Flex
          align={'center'}
          justify={'space-between'}
          onClick={onToggle}
          {...activeStyleProps}
          {...styleProps}>
          <Stack align={'center'} direction={'row'} spacing={4}>
            <Icon size={20} />
            <Box as={'span'}>{label}</Box>
          </Stack>

          <ChakraIcon
            as={ChevronDown}
            boxSize={6}
            transition={'all .25s ease-in-out'}
            transform={isOpenCollapse ? 'rotate(180deg)' : ''}
          />
        </Flex>
        <Collapse in={isOpenCollapse} animateOpacity={true}>
          <Stack align={'start'} mt={2} spacing={0}>
            {children.map((child) => {
              return (
                <Link
                  {...styleProps}
                  as={NavLink}
                  activeClassName={'active'}
                  key={child.href}
                  to={child.href}
                  pl={12}
                  w={'full'}
                  sx={{
                    '&.active': {
                      color: mode('primary.500', 'gray.500'),
                      pointerEvents: 'none'
                    }
                  }}
                  _hover={{ bgColor: itemBgHoverDefault }}>
                  {child.label}
                </Link>
              )
            })}
          </Stack>
        </Collapse>
      </Stack>
    )
  }

  return (
    <Link to={href} {...activeStyleProps} {...styleProps}>
      <Stack align={'center'} direction={'row'} spacing={4}>
        <Icon size={20} />
        <Box as={'span'}>{label}</Box>
      </Stack>
    </Link>
  )
}

NavItem.propTypes = {
  href: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.object,
  children: PropTypes.array,
  isActive: PropTypes.bool
}

export default NavItem
