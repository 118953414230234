import React from 'react'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'
import { YMaps, Map, Placemark, FullscreenControl } from 'react-yandex-maps'
import { Box } from '@chakra-ui/react'

import { Card, CardDescription, CardGrid, CardHeader, CardTitle } from '~/components/Card'
import { DetailSkeleton } from '~/components/Skeletons'

function UserDetail (props) {
  const { detail } = props
  const { data, isLoading } = detail

  const marketName = prop('marketName', data)
  const inn = prop('inn', data) || '-'
  const phoneNumber = prop('phoneNumber', data)
  const region = path(['region', 'name'], data)
  const address = prop('address', data)
  const lat = prop('lat', data)
  const lon = prop('lon', data)

  return (
    <DetailSkeleton isLoading={isLoading}>
      <Card>
        <CardHeader>
          <CardTitle>{marketName}</CardTitle>
        </CardHeader>

        <CardGrid>
          <Box>
            <CardDescription title={'INN'} value={inn} />
            <CardDescription title={'Phone number'} value={phoneNumber} />
            <CardDescription title={'Region'} value={region} />
            <CardDescription title={'Address'} value={address} />
          </Box>
          {(lat && lon) && (
            <YMaps>
              <Map defaultState={{ center: [lat, lon], zoom: 15 }} width={'100%'}>
                <Placemark geometry={[lat, lon]} />
                <FullscreenControl />
              </Map>
            </YMaps>
          )}
        </CardGrid>
      </Card>
    </DetailSkeleton>
  )
}

UserDetail.propTypes = {
  detail: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default UserDetail
