import * as API from '~/constants/api'
import { useCreate, useList } from '~/hooks/crud'
import { useGetSearchParams } from '~/hooks/urls'

export function useTokenList () {
  const params = useGetSearchParams()
  return useList(API.TOKEN_LIST, params)
}

export function useTokenCreate () {
  return useCreate(API.TOKEN_CREATE)
}
