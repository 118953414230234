import React from 'react'
import { useParams } from 'react-router-dom'

import { useRedirectEdit, useBannerDetail, useBannerDelete } from '../hooks'
import { BannerDetail } from '../components/BannerDetail'

import { useConfirm } from '~/hooks/app'
import { RouterNavBar } from '~/components/NavBar'

export default function BannerDetailContainer () {
  const { id } = useParams()
  const detail = useBannerDetail(id)
  const remove = useBannerDelete(id)

  const onDelete = useConfirm({
    remove,
    alertParams: { title: 'Remove banner' },
    redirectPath: 'back'
  })
  const onEdit = useRedirectEdit(id)

  return (
    <RouterNavBar title={'Banner detail'}>
      <BannerDetail
        detail={detail}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </RouterNavBar>
  )
}
