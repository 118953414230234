import React from 'react'
import { Box, useColorModeValue } from '@chakra-ui/react'

export default function Paper (props) {
  return (
    <Box
      bgColor={useColorModeValue('white', 'gray.800')}
      boxShadow={'md'}
      rounded={'md'}
      p={8}
      {...props}
    />
  )
}
