import { fromPairs, join, map, pipe, split, toPairs } from 'ramda'

export function parseParams (search) {
  const [, splitSearch] = split('?', search)
  const searchToObject = pipe(
    split('&'),
    map(split('=')),
    fromPairs
  )
  return splitSearch ? searchToObject(splitSearch) : {}
}

export function paramsToSearch (params) {
  return pipe(
    toPairs,
    map(join('=')),
    join('&')
  )(params)
}
