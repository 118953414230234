import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import * as API from '~/constants/api'
import { ImageCrop, Input, SearchField, withForm } from '~/components/FormElements'
import { Card, CardButtons, CardGrid, FieldStack } from '~/components/Card'

function CategoryCreate (props) {
  const { form, isLoading, isLoadingData } = props
  const { handleSubmit } = form

  return (
    <Card as={'form'} isLoading={isLoadingData} onSubmit={handleSubmit}>
      <CardGrid>
        <FieldStack direction={'row'}>
          <Field
            name={'photo'}
            component={ImageCrop}
            label={'Photo'}
            isRequired={true}
          />
          <FieldStack flexGrow={'1'}>
            <Field
              name={'name'}
              component={Input}
              label={'Category name'}
              placeholder={'Category name'}
              isRequired={true}
            />
            <Field
              name={'parent'}
              component={SearchField}
              api={API.CATEGORY_LIST}
              label={'Parent category'}
            />
          </FieldStack>
        </FieldStack>
      </CardGrid>

      <CardButtons isLoading={isLoading} />
    </Card>
  )
}

CategoryCreate.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingData: PropTypes.bool
}

export default withForm(CategoryCreate)
