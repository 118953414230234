import React from 'react'
import PropTypes from 'prop-types'
import { MoreVertical } from 'react-feather'
import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal
} from '@chakra-ui/react'

function DataDropdown (props) {
  const { children, toDetail, onEdit, onDelete } = props

  function onClickButton (event) {
    event.stopPropagation()
  }

  return (
    <Box textAlign={'right'} onClick={onClickButton}>
      <Menu isLazy={true}>
        <MenuButton
          as={IconButton}
          aria-label={'Options'}
          colorScheme={'gray'}
          variant={'ghost'}
          icon={<MoreVertical size={20} />}
          size={'sm'}
        />
        <Portal>
          <MenuList>
            {toDetail && (
              <MenuItem onClick={toDetail}>
                Details
              </MenuItem>
            )}
            {onEdit && (
              <MenuItem onClick={onEdit}>
                Edit
              </MenuItem>
            )}
            {onDelete && (
              <MenuItem onClick={onDelete}>
                Delete
              </MenuItem>
            )}

            {children}
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  )
}

DataDropdown.propTypes = {
  children: PropTypes.node,
  toDetail: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
}

export default DataDropdown
