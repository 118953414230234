import { filled } from '../variants/inputVariants'

export default {
  defaultProps: {
    colorScheme: 'primary',
    variant: 'filled'
  },
  baseStyle: {
    display: 'block',
    lineHeight: 'base',
    resize: 'none'
  },
  sizes: {
    md: {
      minH: '132px'
    }
  },
  variants: {
    filled: props => ({
      ...filled(props)
    })
  }
}
