import { TokenCreateContainer, TokenListContainer } from './containers'

import * as ROUTES from '~/constants/routes'
import { MainLayout } from '~/components/Layouts'

export default [{
  path: ROUTES.TOKEN_LIST_PATH,
  layout: MainLayout,
  component: TokenListContainer,
  routes: [
    {
      path: ROUTES.TOKEN_CREATE_PATH,
      layout: MainLayout,
      component: TokenCreateContainer
    }
  ]
}]
