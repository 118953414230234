import { useTheme } from '@chakra-ui/react'

export default function useSelectStyles () {
  const { colors, ...theme } = useTheme()

  const transition = 'all 200ms'

  const control = (provided, state) => {
    const bgColor = state.theme.colors.neutral10
    const bgColorHover = state.theme.colors.neutral20
    const bgColorFocus = state.theme.colors.neutral0

    return {
      ...provided,
      backgroundColor: state.isFocused ? bgColorFocus : bgColor,
      borderColor: state.isFocused ? state.theme.colors.primary : colors.transparent,
      borderRadius: state.theme.borderRadius,
      transition,
      '&:hover': {
        backgroundColor: state.isFocused ? bgColorFocus : bgColorHover,
        borderColor: state.isFocused ? state.theme.colors.primary : colors.transparent
      }
    }
  }

  const clearIndicator = (provided, state) => ({
    ...provided,
    color: state.theme.colors.neutral50,
    '&:hover': {
      color: state.theme.colors.neutral40
    }
  })

  const dropdownIndicator = (provided, state) => ({
    ...provided,
    alignItems: 'center',
    color: state.isFocused ? state.theme.colors.primary : state.theme.colors.neutral50,
    pointerEvents: 'none'
  })

  const indicatorSeparator = (provided, state) => ({
    ...provided,
    backgroundColor: state.theme.colors.neutral40
  })

  const placeholder = (provided, state) => ({
    ...provided,
    color: state.theme.colors.neutral50,
    marginLeft: 0,
    marginRight: 0
  })

  const menu = (provided, state) => {
    const backgroundColor = state.theme.colors.neutral0
    const borderColor = state.theme.colors.neutral20

    return {
      ...provided,
      backgroundColor,
      borderColor,
      borderWidth: 1,
      boxShadow: theme.shadows.md,
      borderRadius: state.theme.borderRadius
    }
  }

  const menuList = provided => ({
    ...provided,
    padding: theme.space[2]
  })

  const option = (provided, state) => {
    const backgroundColor = colors.transparent
    const backgroundColorSelected = state.theme.colors.neutral30
    const backgroundColorActive = state.theme.colors.neutral20
    const backgroundColorHover = state.theme.colors.neutral10

    return {
      ...provided,
      backgroundColor: state.isSelected ? backgroundColorSelected : backgroundColor,
      borderRadius: state.theme.borderRadius,
      color: 'inherit',
      cursor: 'pointer',
      transition,
      '&:active': {
        backgroundColor: backgroundColorActive
      },
      '&:hover:not(:active)': {
        backgroundColor: state.isSelected ? backgroundColorSelected : backgroundColorHover
      }
    }
  }

  const singleValue = provided => ({
    ...provided,
    fontSize: 'inherit',
    color: 'inherit'
  })

  const valueContainer = (provided, state) => {
    const singleValueSidePadding = `calc(${theme.space[4]} - 2px)`
    const isMultiWithValues = state.hasValue && state.isMulti

    return {
      ...provided,
      padding: isMultiWithValues ? theme.space[1] : `2px ${singleValueSidePadding}`
    }
  }

  return {
    control,
    clearIndicator,
    dropdownIndicator,
    indicatorSeparator,
    placeholder,
    menu,
    menuList,
    option,
    singleValue,
    valueContainer
  }
}
