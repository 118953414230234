import React from 'react'
import PropTypes from 'prop-types'
import isEqual from 'react-fast-compare'

import Form from './Form'

export default function withForm (Component, FormProps) {
  function FormComponent (props) {
    const { onSubmit, initialValues, validate, ...restProps } = props

    return (
      <Form
        onSubmit={onSubmit}
        initialValuesEqual={isEqual}
        initialValues={initialValues}
        validate={validate}
        {...FormProps}
        render={form => (
          <Component form={form} {...restProps} />
        )}
      />
    )
  }

  FormComponent.propTypes = {
    onSubmit: PropTypes.func,
    validate: PropTypes.func,
    initialValues: PropTypes.any
  }

  return React.memo(FormComponent, isEqual)
}
