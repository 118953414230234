import { useHistory } from 'react-router-dom'

import useAppendParamsToUrl from './useAppendParamsToUrl'

export default function useReplaceParams () {
  const history = useHistory()
  const appendParamsToUrl = useAppendParamsToUrl()

  function replaceParams (params) {
    const formedPathname = appendParamsToUrl(params)
    history.replace(formedPathname)
  }

  return replaceParams
}
