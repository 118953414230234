import React from 'react'
import { Field } from 'react-final-form'
import { Stack } from '@chakra-ui/react'

import { Input } from '~/components/FormElements'

export default function DashboardFilter () {
  return (
    <Stack spacing={4}>
      <Field
        name={'key1'}
        component={Input}
        label={'Key 1'}
        placeholder={'Key 1'}
      />
      <Field
        name={'key2'}
        component={Input}
        label={'Key 2'}
      />
    </Stack>
  )
}
