import React from 'react'
import { useParams } from 'react-router-dom'

import { useRedirectEdit, useProductDetail, useProductDelete } from '../hooks'
import { ProductDetail } from '../components/ProductDetail'

import { useConfirm } from '~/hooks/app'
import { RouterNavBar } from '~/components/NavBar'

export default function ProductDetailContainer () {
  const { id } = useParams()
  const detail = useProductDetail(id)
  const remove = useProductDelete(id)

  const onDelete = useConfirm({
    remove,
    alertParams: { title: 'Remove provider' },
    redirectPath: 'back'
  })
  const onEdit = useRedirectEdit(id)

  return (
    <RouterNavBar title={'Product detail'}>
      <ProductDetail
        detail={detail}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </RouterNavBar>
  )
}
