import { useHistory } from 'react-router-dom'

import useToast from '~/hooks/useToast'
import { useAlertDialog } from '~/components/AlertDialog'

export default function useConfirm (params) {
  const { remove, alertParams, onSuccess, redirectPath } = params

  const history = useHistory()
  const toast = useToast()
  const alertDialog = useAlertDialog()

  return function () {
    alertDialog.onOpen({
      ...alertParams,
      onConfirm: () => {
        remove.remove()
          .then(() => {
            alertDialog.onClose()
            toast({
              status: 'success',
              title: 'Deleted',
              description: 'Deleted successfully'
            })

            if (redirectPath && redirectPath === 'back') {
              history.goBack()
            } else if (redirectPath) {
              history.replace(redirectPath)
            } else if (typeof onSuccess === 'function') {
              onSuccess()
            }
          })
          .catch(error => {
            toast({
              status: 'error',
              title: 'Error',
              description: JSON.stringify(error)
            })
          })
      }
    })
  }
}
