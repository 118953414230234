const ID = ':id(\\d+)'
const CREATE = 'create'
const UPDATE = 'update'

export const ROOT_PATH = '/'

const LOGIN = 'login'
export const LOGIN_PATH = `/${LOGIN}/`

// Providers
const PROVIDERS = 'providers'
export const PROVIDER_LIST_PATH = `/${PROVIDERS}/`
export const PROVIDER_CREATE_PATH = `/${PROVIDERS}/${CREATE}/`
export const PROVIDER_UPDATE_PATH = `/${PROVIDERS}/${ID}/${UPDATE}/`
export const PROVIDER_DETAIL_PATH = `/${PROVIDERS}/${ID}/`
export const PROVIDER_UPDATE_URL = `/${PROVIDERS}/%d/${UPDATE}/`
export const PROVIDER_DETAIL_URL = `/${PROVIDERS}/%d/`

// Brands
const BRANDS = 'brands'
export const BRAND_LIST_PATH = `/${BRANDS}/`
export const BRAND_CREATE_PATH = `/${BRANDS}/${CREATE}/`
export const BRAND_UPDATE_PATH = `/${BRANDS}/${ID}/${UPDATE}/`
export const BRAND_DETAIL_PATH = `/${BRANDS}/${ID}/`
export const BRAND_UPDATE_URL = `/${BRANDS}/%d/${UPDATE}/`
export const BRAND_DETAIL_URL = `/${BRANDS}/%d/`

// Categories
const CATEGORIES = 'categories'
export const CATEGORY_LIST_PATH = `/${CATEGORIES}/`
export const CATEGORY_CREATE_PATH = `/${CATEGORIES}/${CREATE}/`
export const CATEGORY_UPDATE_PATH = `/${CATEGORIES}/${ID}/${UPDATE}/`
export const CATEGORY_DETAIL_PATH = `/${CATEGORIES}/${ID}/`
export const CATEGORY_UPDATE_URL = `/${CATEGORIES}/%d/${UPDATE}/`
export const CATEGORY_DETAIL_URL = `/${CATEGORIES}/%d/`

// Products
const PRODUCTS = 'products'
export const PRODUCT_LIST_PATH = `/${PRODUCTS}/`
export const PRODUCT_CREATE_PATH = `/${PRODUCTS}/${CREATE}/`
export const PRODUCT_UPDATE_PATH = `/${PRODUCTS}/${ID}/${UPDATE}/`
export const PRODUCT_DETAIL_PATH = `/${PRODUCTS}/${ID}/`
export const PRODUCT_UPDATE_URL = `/${PRODUCTS}/%d/${UPDATE}/`
export const PRODUCT_DETAIL_URL = `/${PRODUCTS}/%d/`

// Orders
const ORDERS = 'orders'
export const ORDER_LIST_PATH = `/${ORDERS}/`
export const ORDER_CREATE_PATH = `/${ORDERS}/${CREATE}/`
export const ORDER_UPDATE_PATH = `/${ORDERS}/${ID}/${UPDATE}/`
export const ORDER_DETAIL_PATH = `/${ORDERS}/${ID}/`
export const ORDER_UPDATE_URL = `/${ORDERS}/%d/${UPDATE}/`
export const ORDER_DETAIL_URL = `/${ORDERS}/%d/`

// Users
const USERS = 'users'
export const USER_LIST_PATH = `/${USERS}/`
export const USER_CREATE_PATH = `/${USERS}/${CREATE}/`
export const USER_UPDATE_PATH = `/${USERS}/${ID}/${UPDATE}/`
export const USER_DETAIL_PATH = `/${USERS}/${ID}/`
export const USER_UPDATE_URL = `/${USERS}/%d/${UPDATE}/`
export const USER_DETAIL_URL = `/${USERS}/%d/`

// Settings
const SETTINGS = 'settings'
const REGIONS = `${SETTINGS}/regions`
export const REGION_LIST_PATH = `/${REGIONS}/`
export const REGION_CREATE_PATH = `/${REGIONS}/${CREATE}/`
export const REGION_UPDATE_PATH = `/${REGIONS}/${ID}/${UPDATE}/`
export const REGION_DETAIL_PATH = `/${REGIONS}/${ID}/`
export const REGION_UPDATE_URL = `/${REGIONS}/%d/${UPDATE}/`
export const REGION_DETAIL_URL = `/${REGIONS}/%d/`

const BANNERS = `${SETTINGS}/banners`
export const BANNER_LIST_PATH = `/${BANNERS}/`
export const BANNER_CREATE_PATH = `/${BANNERS}/${CREATE}/`
export const BANNER_UPDATE_PATH = `/${BANNERS}/${ID}/${UPDATE}/`
export const BANNER_DETAIL_PATH = `/${BANNERS}/${ID}/`
export const BANNER_UPDATE_URL = `/${BANNERS}/%d/${UPDATE}/`
export const BANNER_DETAIL_URL = `/${BANNERS}/%d/`

const MEASUREMENTS = `${SETTINGS}/measurements`
export const MEASUREMENT_LIST_PATH = `/${MEASUREMENTS}/`
export const MEASUREMENT_CREATE_PATH = `/${MEASUREMENTS}/${CREATE}/`
export const MEASUREMENT_UPDATE_PATH = `/${MEASUREMENTS}/${ID}/${UPDATE}/`
export const MEASUREMENT_DETAIL_PATH = `/${MEASUREMENTS}/${ID}/`
export const MEASUREMENT_UPDATE_URL = `/${MEASUREMENTS}/%d/${UPDATE}/`
export const MEASUREMENT_DETAIL_URL = `/${MEASUREMENTS}/%d/`

const TOKENS = `${SETTINGS}/tokens`
export const TOKEN_LIST_PATH = `/${TOKENS}/`
export const TOKEN_CREATE_PATH = `/${TOKENS}/${CREATE}/`
