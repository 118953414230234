import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox as ChakraCheckbox } from '@chakra-ui/react'

import FormControl from '../FormControl'

function Checkbox (props) {
  const { input, meta, label, isRequired, ...restProps } = props

  return (
    <FormControl id={input.name} isRequired={isRequired}>
      <ChakraCheckbox {...input} {...restProps}>
        {label}
      </ChakraCheckbox>
    </FormControl>
  )
}

Checkbox.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
  label: PropTypes.string,
  isRequired: PropTypes.bool
}

export default Checkbox
