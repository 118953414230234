import Login from './components/Login'

import * as ROUTES from '~/constants/routes'
import { BlankLayout } from '~/components/Layouts'

export default [{
  path: ROUTES.LOGIN_PATH,
  layout: BlankLayout,
  component: Login
}]
