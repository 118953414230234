import React from 'react'

import {
  useRedirectDetail,
  useRedirectEdit,
  useProviderList
} from '../hooks'
import { ProviderList } from '../components/ProviderList'

import { NAV_KEYS } from '~/constants/nav'
import { AppBar } from '~/components/AppBar'

export default function ProviderListContainer () {
  const list = useProviderList()
  const toDetail = useRedirectDetail()
  const onEdit = useRedirectEdit()

  return (
    <AppBar activeNav={NAV_KEYS.PROVIDERS}>
      <ProviderList
        list={list}
        toDetail={toDetail}
        onEdit={onEdit}
      />
    </AppBar>
  )
}
