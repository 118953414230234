import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'

import useSelectStyles from './useSelectStyles'
import useSelectTheme from './useSelectTheme'

function Select (props) {
  const { options, onMenuOpen, onMenuClose, ...restProps } = props
  const { isLoading } = restProps

  const [openMenu, setOpenMenu] = useState(false)
  const styles = useSelectStyles()
  const getTheme = useSelectTheme()

  function handleMenuOpen () {
    setOpenMenu(true)
    if (typeof onMenuOpen === 'function') {
      onMenuOpen()
    }
  }

  function handleMenuClose (event) {
    setOpenMenu(false)
    if (typeof onMenuClose === 'function') {
      onMenuClose()
    }
  }

  function onChange (option, action) {
    if (typeof restProps.onChange === 'function') {
      restProps.onChange(option)
    }
  }

  const menuIsOpen = isLoading ? false : openMenu

  return (
    <ReactSelect
      options={options}
      styles={styles}
      theme={getTheme}
      isClearable={true}
      type={'select'}
      menuIsOpen={menuIsOpen}
      menuPortalTarget={document.body}
      {...restProps}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
      onChange={onChange}
    />
  )
}

Select.propTypes = {
  isLoading: PropTypes.bool,
  options: PropTypes.array,
  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func,
  onChange: PropTypes.func
}

export default Select
