import React, { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { find, prop, propEq, is, unionWith, eqBy } from 'ramda'

import FormControl from '../FormControl'

import { useDebounce } from '~/hooks/app'
import Select from '~/components/Select'

const reducer = (state, action) => ({ ...state, ...action })

const initialState = {
  options: [],
  loading: false,
  query: '',
  dirty: false
}

const getIdFromValue = inputValue => {
  if (is(Object, inputValue)) {
    return prop('id', inputValue)
  }
  return inputValue
}

const getSelectedOption = (options, value, isStatic) => {
  const optionId = isStatic
    ? prop('id', value) || value
    : parseInt(prop('id', value) || value)
  const option = find(propEq('id', optionId))(options)
  return option || ''
}

const onFetchData = (props, state, dispatch) => {
  const { getOptions, getValue, getText } = props

  dispatch({ loading: true, dirty: true })
  getOptions(state.query)
    .then(data => {
      const options = data.map(item => {
        const id = getValue(item)
        const name = getText(item)
        return { ...item, id, name }
      })

      dispatch({ options, loading: false })
    })
}

function SearchFieldBase (props) {
  const {
    parent,
    input,
    label,
    disabled,
    getOptions,
    getOption,
    getValue,
    getText,
    isRequired,
    isStatic,
    isMulti,
    ...restProps
  } = props

  const [state, dispatch] = useReducer(reducer, initialState)

  const { name, value, ...restInput } = input
  const inputValueId = getIdFromValue(value)
  const selectedOption = getSelectedOption(state.options, value, isStatic)
  const debouncedQuery = useDebounce(state.query, 500)

  const onMenuOpen = () => {
    if (!state.dirty) {
      onFetchData(props, state, dispatch)
    }
  }

  useEffect(() => {
    if (parent) {
      onFetchData(props, state, dispatch)
    }
  }, [parent])

  useEffect(() => {
    if (debouncedQuery) {
      onFetchData(props, state, dispatch)
    }
  }, [debouncedQuery])

  useEffect(() => {
    if (inputValueId) {
      dispatch({ loading: true })
      getOption(inputValueId)
        .then(item => {
          const option = {
            id: getValue(item),
            name: getText(item)
          }
          const options = unionWith(
            eqBy(prop('id')),
            state.options,
            [option]
          )

          dispatch({ options, loading: false })
        })
    }
  }, [inputValueId])

  const onInputChange = (query, { action }) => {
    if (action === 'input-change') {
      if (!state.dirty) {
        dispatch({ dirty: true })
      }
      dispatch({ query })
    }
  }

  const selectDefaultProps = {
    ...restProps,
    options: state.options,
    isLoading: state.loading,
    isClearable: true,
    getOptionLabel: prop('name'),
    getOptionValue: prop('id'),
    onMenuOpen,
    onInputChange
  }

  return (
    <FormControl id={name} isRequired={isRequired} label={label}>
      <Select
        inputId={name}
        {...selectDefaultProps}
        {...restInput}
        value={selectedOption}
      />
    </FormControl>
  )
}

SearchFieldBase.propTypes = {
  input: PropTypes.object,
  parent: PropTypes.any,
  inputRef: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  getOptions: PropTypes.func.isRequired,
  getOption: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  getText: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  isStatic: PropTypes.bool,
  isMulti: PropTypes.bool
}

export default SearchFieldBase
