import { mode } from '@chakra-ui/theme-tools'

export default {
  baseStyle: {
    th: {
      letterSpacing: 'normal',
      textTransform: 'unset'
    }
  },
  sizes: {
    md: {
      th: {
        fontSize: 'md'
      }
    }
  },
  variants: {
    simple: ({ colorScheme, ...rest }) => {
      const borderColor = mode(`${colorScheme}.200`, `${colorScheme}.800`)(rest)
      const headerBgColor = mode(`${colorScheme}.200`, `${colorScheme}.800`)(rest)

      return {
        table: {
          borderColor,
          borderWidth: 1
        },
        thead: {
          bgColor: headerBgColor
        },
        th: {
          borderColor
        },
        td: {
          borderColor
        }
      }
    }
  }
}
