import React from 'react'
import { useHistory } from 'react-router-dom'

import { productCreateSerializer } from '../serializers'
import { useProductCreate } from '../hooks'
import { ProductCreate } from '../components/ProductCreate'

import useToast, { SUCCESS_CREATE_PARAMS } from '~/hooks/useToast'
import { RouterNavBar } from '~/components/NavBar'

export default function ProductCreateContainer () {
  const history = useHistory()
  const toast = useToast()
  const { create, isLoading } = useProductCreate()

  function onSubmit (formValues) {
    return create(productCreateSerializer(formValues))
      .then(() => toast(SUCCESS_CREATE_PARAMS))
      .then(() => history.goBack())
  }

  return (
    <RouterNavBar title={'Product create'}>
      <ProductCreate
        onSubmit={onSubmit}
        isLoading={isLoading}
      />
    </RouterNavBar>
  )
}
