import React from 'react'

import {
  useRedirectDetail,
  useRedirectEdit,
  useOrderList
} from '../hooks'
import { OrderList } from '../components/OrderList'

import { NAV_KEYS } from '~/constants/nav'
import { AppBar } from '~/components/AppBar'

export default function OrderListContainer () {
  const list = useOrderList()
  const toDetail = useRedirectDetail()
  const onEdit = useRedirectEdit()

  return (
    <AppBar activeNav={NAV_KEYS.ORDER}>
      <OrderList
        list={list}
        toDetail={toDetail}
        onEdit={onEdit}
      />
    </AppBar>
  )
}
