import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Heading } from '@chakra-ui/react'

import { TableContext } from './TableContext'
import TableActions from './TableActions'
import Table from './Table'

import Paper from '~/components/Paper'

function TableProvider (props) {
  const {
    children,
    title,
    list,
    isLoading,
    isCreatable,
    spacing,
    filter,
    filterForm
  } = props

  const tableProps = {
    list,
    isLoading,
    filter,
    filterForm,
    isCreatable,
    spacing,
    rowMinHeight: 50
  }

  return (
    <TableContext.Provider value={tableProps}>
      <Paper px={8} py={6}>
        <Heading fontSize={'2xl'} fontWeight={'semibold'} mb={6}>
          {title}
        </Heading>

        <TableActions />

        <Table>{children}</Table>
      </Paper>
    </TableContext.Provider>
  )
}

export function useTable () {
  return useContext(TableContext)
}

TableProvider.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  list: PropTypes.array,
  isLoading: PropTypes.bool,
  isCreatable: PropTypes.bool,
  spacing: PropTypes.number,
  filter: PropTypes.object,
  filterForm: PropTypes.node
}

TableProvider.defaultProps = {
  isCreatable: true,
  spacing: 8
}

export default TableProvider
