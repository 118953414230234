import { useReplaceParams, useGetBolean } from '~/hooks/urls'

export default params => {
  const { key = 'modal', autoClose = false } = params

  const replaceParams = useReplaceParams()
  const isOpen = useGetBolean(key)

  const onClose = () => {
    replaceParams({ [key]: false })
  }

  const onOpen = () => {
    replaceParams({ [key]: true })
  }

  const onSubmit = (event) => autoClose
    ? params.onSubmit(event).then(() => onClose())
    : params.onSubmit(event, onClose)

  return {
    isOpen,
    onOpen,
    onClose,
    onSubmit
  }
}
