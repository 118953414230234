import { sprintf } from 'sprintf-js'

import * as API from '~/constants/api'
import * as ROUTES from '~/constants/routes'
import { useCreate, useDetail, useList, useRemove, useUpdate } from '~/hooks/crud'
import { useGetSearchParams } from '~/hooks/urls'
import { useRedirect } from '~/hooks/app'

export function useRedirectDetail () {
  return useRedirect(ROUTES.REGION_DETAIL_URL)
}

export function useRedirectEdit () {
  return useRedirect(ROUTES.REGION_UPDATE_URL)
}

export function useRegionList () {
  const params = useGetSearchParams()
  return useList(API.REGION_LIST, params)
}

export function useRegionDetail (id) {
  const api = sprintf(API.REGION_DETAIL, id)
  return useDetail(api)
}

export function useRegionDelete (id) {
  const api = sprintf(API.REGION_DELETE, id)
  return useRemove(api)
}

export function useRegionCreate () {
  return useCreate(API.REGION_CREATE)
}

export function useRegionUpdate (id) {
  const api = sprintf(API.REGION_UPDATE, id)
  return useUpdate(api)
}
