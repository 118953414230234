import React from 'react'
import PropTypes from 'prop-types'
import { Image } from '@chakra-ui/react'

function PhotoGridImage (props) {
  const { src, ...restProps } = props

  return (
    <Image
      display={'block'}
      h={40}
      objectFit={'cover'}
      src={src}
      w={'full'}
      {...restProps}
    />
  )
}

PhotoGridImage.propTypes = {
  src: PropTypes.string
}

export default PhotoGridImage
