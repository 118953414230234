import React from 'react'
import { Form, Field } from 'react-final-form'
import { Search } from 'react-feather'
import { Box } from '@chakra-ui/react'

import { useGetSearchParam, useReplaceParams } from '~/hooks/urls'
import { Input } from '~/components/FormElements'
import { Icon } from '~/components/Chakra'

export default function TableSearch () {
  const search = useGetSearchParam('search')
  const replaceParams = useReplaceParams()

  function onSubmit ({ search: formSearch }) {
    replaceParams({ search: formSearch, page: 1 })
  }

  return (
    <Form
      initialValues={{ search }}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <Box as={'form'} onSubmit={handleSubmit}>
          <Field
            name={'search'}
            component={Input}
            placeholder={'Search'}
            leftElement={<Icon as={Search} />}
          />
        </Box>
      )}
    />
  )
}
