import axios from 'axios'

import toSnakeCase from './toSnakeCase'

import { API_BASE_URL } from '~/constants/api'
import { LOGIN_PATH } from '~/constants/routes'
import { getToken, removeToken } from '~/utils/cookies'
import transformResponse from '~/utils/transformResponse'

const errorStatuses = {
  unauthorized: 401
}

function onFulfilled (response) {
  return response
}

function onRejected (error) {
  const status = error.response.status
  if (error.message === 'Network Error') {
    const networkError = {
      response: {
        data: {
          nonFieldErrors: ['No internet.']
        }
      }
    }

    return Promise.reject(networkError)
  }

  if (status === errorStatuses.unauthorized) {
    removeToken()
    window.history.pushState({}, '', LOGIN_PATH)
  }

  return Promise.reject(error)
}

async function getHeaders () {
  const token = getToken()

  if (token) {
    return {
      Authorization: `Bearer ${token}`
    }
  }

  return {}
}

function getHttpRequest () {
  const config = {
    baseURL: API_BASE_URL,
    transformResponse: [transformResponse]
  }

  const instance = axios.create(config)
  instance.interceptors.response.use(onFulfilled, onRejected)

  return instance
}

export default {
  get: async function (url, params) {
    const http = getHttpRequest()
    const headers = await getHeaders()

    return http({
      method: 'get',
      params: toSnakeCase(params),
      url,
      headers
    })
  },
  post: async function (url, data) {
    const http = getHttpRequest()
    const headers = await getHeaders()

    return http({
      method: 'post',
      data: toSnakeCase(data),
      url,
      headers
    })
  },
  put: async function (url, data) {
    const http = getHttpRequest()
    const headers = await getHeaders()

    return http({
      method: 'put',
      data: toSnakeCase(data),
      url,
      headers
    })
  },
  delete: async function (url, data) {
    const http = getHttpRequest()
    const headers = await getHeaders()

    return http({
      method: 'delete',
      data: toSnakeCase(data),
      url,
      headers
    })
  },
  upload: async function (url, data, onUploadProgress) {
    const http = getHttpRequest()
    const headers = await getHeaders()

    return http({
      method: 'post',
      data,
      url,
      headers: {
        ...headers,
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    })
  }
}
