import React from 'react'
import { Box, useColorModeValue } from '@chakra-ui/react'

export default function TableHead (props) {
  return (
    <Box
      bgColor={useColorModeValue('gray.200', 'gray.900')}
      color={useColorModeValue('gray.600', 'gray.400')}
      fontWeight={'medium'}
      rounded={'md'}
      {...props}
    />
  )
}
