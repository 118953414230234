import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import {
  FormControl as ChakraFormControl,
  FormLabel,
  FormErrorMessage
} from '@chakra-ui/react'

function FormControl (props) {
  const { children, id, isRequired, label } = props

  const { meta: { submitError, touched } } = useField(id, {
    subscription: { touched: true, submitError: true }
  })

  const isInvalid = !!(submitError && touched)

  return (
    <ChakraFormControl id={id} isRequired={isRequired} isInvalid={isInvalid}>
      {label && (
        <FormLabel>{label}</FormLabel>
      )}

      {children}

      <FormErrorMessage>
        {submitError}
      </FormErrorMessage>
    </ChakraFormControl>
  )
}

FormControl.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  // meta: PropTypes.object.isRequired,
  isRequired: PropTypes.bool,
  label: PropTypes.string
}

export default FormControl
