import React from 'react'
import PropTypes from 'prop-types'
import { Textarea as ChakraTextarea } from '@chakra-ui/react'

import FormControl from '../FormControl'

function Textarea (props) {
  const { input, meta, label, isRequired, ...restProps } = props

  return (
    <FormControl
      id={input.name}
      isRequired={isRequired}
      label={label}>
      <ChakraTextarea {...input} {...restProps} />
    </FormControl>
  )
}

Textarea.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  isRequired: PropTypes.bool
}

export default Textarea
