import React, { Fragment } from 'react'
import { Form } from 'react-final-form'
import { Filter } from 'react-feather'
import {
  Button,
  ButtonGroup,
  CloseButton,
  Flex,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Stack,
  Text
} from '@chakra-ui/react'

import { useTable } from './TableProvider'

import { Icon } from '~/components/Chakra'

export default function TableFilter () {
  const { filter, filterForm } = useTable()

  if (!filterForm) {
    return null
  }

  const {
    isOpen,
    onOpen,
    onClose,
    onSubmit,
    onClear,
    initialValues,
    count
  } = filter

  return (
    <Fragment>
      <Button
        leftIcon={<Icon as={Filter} />}
        colorScheme={'gray'}
        variant={'outline'}
        onClick={onOpen}>
        {count ? `Filter: ${count}` : 'Filter'}
      </Button>

      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={'right'}
        preserveScrollBarGap={true}
        scrollBehavior={'inside'}
        size={'sm'}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerHeader px={8}>
              <Flex align={'center'} justify={'space-between'}>
                <Text>Filter</Text>
                <CloseButton size={'lg'} onClick={onClose} />
              </Flex>
            </DrawerHeader>

            <Form
              onSubmit={onSubmit}
              initialValues={initialValues}
              render={({ handleSubmit }) => (
                <DrawerBody
                  direction={'column'}
                  px={8}
                  py={4}>
                  <Stack as={'form'} spacing={10} onSubmit={handleSubmit}>
                    {filterForm}

                    <ButtonGroup spacing={2} size={'lg'}>
                      {!!count && (
                        <Button colorScheme={'gray'} isFullWidth={true} onClick={onClear}>
                          Clear
                        </Button>
                      )}
                      <Button isFullWidth={true} type={'submit'}>
                        Apply
                      </Button>
                    </ButtonGroup>
                  </Stack>
                </DrawerBody>
              )}
            />
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Fragment>
  )
}
