import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { Box, Text } from '@chakra-ui/react'

import { useTable } from './TableProvider'

import { TableSkeleton } from '~/components/Skeletons'

function Table ({ children }) {
  const { isLoading, list } = useTable()

  if (isLoading) {
    return (
      <Box mt={10}>
        <TableSkeleton />
      </Box>
    )
  }

  if (list && isEmpty(list)) {
    return (
      <Box mt={10}>
        {children}
        <Box rounded={'md'} p={8}>
          <Text fontSize={'lg'} textAlign={'center'}>No data to display</Text>
        </Box>
      </Box>
    )
  }

  return (
    <Box mt={10}>
      {children}
    </Box>
  )
}

Table.propTypes = {
  children: PropTypes.node.isRequired
}

export default Table
