import React from 'react'

import {
  useRedirectDetail,
  useRedirectEdit,
  useRegionList
} from '../hooks'
import { RegionList } from '../components/RegionList'

import { NAV_KEYS } from '~/constants/nav'
import { AppBar } from '~/components/AppBar'

export default function RegionListContainer () {
  const list = useRegionList()
  const toDetail = useRedirectDetail()
  const onEdit = useRedirectEdit()

  return (
    <AppBar activeNav={NAV_KEYS.SETTINGS}>
      <RegionList
        list={list}
        toDetail={toDetail}
        onEdit={onEdit}
      />
    </AppBar>
  )
}
