import {
  UserListContainer,
  UserDetailContainer,
  UserCreateContainer,
  UserUpdateContainer
} from './containers'

import * as ROUTES from '~/constants/routes'
import { MainLayout } from '~/components/Layouts'

export default [{
  path: ROUTES.USER_LIST_PATH,
  layout: MainLayout,
  component: UserListContainer,
  routes: [
    {
      path: ROUTES.USER_DETAIL_PATH,
      layout: MainLayout,
      component: UserDetailContainer
    },
    {
      path: ROUTES.USER_CREATE_PATH,
      layout: MainLayout,
      component: UserCreateContainer
    },
    {
      path: ROUTES.USER_UPDATE_PATH,
      layout: MainLayout,
      component: UserUpdateContainer
    }
  ]
}]
