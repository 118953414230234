import React from 'react'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'

import { numberFormat } from '~/utils/number'
import { dateFormat } from '~/utils/date'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCol
} from '~/components/Table'

function OrderList (props) {
  const { list } = props

  const { results, isLoading } = list

  return (
    <Table
      title={'Orders'}
      list={results}
      isLoading={isLoading}
      isCreatable={false}>
      <TableHead>
        <TableRow>
          <TableCol span={8}>Provider</TableCol>
          <TableCol span={6}>Total price</TableCol>
          <TableCol span={5}>Date</TableCol>
          <TableCol span={5}>Status</TableCol>
        </TableRow>
      </TableHead>
      <TableBody>
        {results.map(item => {
          const id = prop('id', item)
          const provider = path(['provider', 'name'], item)
          const totalPrice = numberFormat(prop('totalPrice', item))
          const createdDate = dateFormat(prop('createdDate', item))
          const status = prop('status', item)

          return (
            <TableRow key={id}>
              <TableCol span={8}>{provider}</TableCol>
              <TableCol span={6}>{totalPrice}</TableCol>
              <TableCol span={5}>{createdDate}</TableCol>
              <TableCol span={5}>{status}</TableCol>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

OrderList.propTypes = {
  list: PropTypes.object.isRequired,
  toDetail: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired
}

export default OrderList
