import { prop, path } from 'ramda'

export const productCreateSerializer = values => {
  const photo = path(['photo', 'id'], values)

  return {
    productImages: [{ photoId: photo, isMain: true }],
    name: prop('name', values),
    categoryId: path(['category', 'id'], values),
    brandId: path(['brand', 'id'], values),
    providerId: path(['provider', 'id'], values),
    measurementId: path(['measurement', 'id'], values),
    barcode: prop('barcode', values),
    price: prop('price', values),
    cashback: prop('cashback', values),
    description: prop('description', values)
  }
}
