import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCol
} from '~/components/Table'
import { DataDropdown } from '~/components/DataDropdown'

function ProviderList (props) {
  const { list, toDetail, onEdit } = props

  const { results, isLoading } = list

  return (
    <Table
      title={'Providers'}
      list={results}
      isLoading={isLoading}>
      <TableHead>
        <TableRow>
          <TableCol span={12}>Name</TableCol>
          <TableCol span={6}>INN</TableCol>
          <TableCol span={4}>Cashback</TableCol>
          <TableCol span={2} />
        </TableRow>
      </TableHead>
      <TableBody>
        {results.map(item => {
          const id = prop('id', item)
          const name = prop('name', item)
          const inn = prop('inn', item)
          const cashback = prop('cashback', item)

          return (
            <TableRow key={id} onClick={toDetail.bind(null, id)}>
              <TableCol span={12}>{name}</TableCol>
              <TableCol span={6}>{inn}</TableCol>
              <TableCol span={4}>{cashback}</TableCol>
              <TableCol span={2}>
                <DataDropdown
                  onEdit={onEdit.bind(null, id)}
                />
              </TableCol>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

ProviderList.propTypes = {
  list: PropTypes.object.isRequired,
  toDetail: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired
}

export default ProviderList
