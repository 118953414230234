import { sprintf } from 'sprintf-js'

import * as API from '~/constants/api'
import * as ROUTES from '~/constants/routes'
import { useCreate, useDetail, useList, useRemove, useUpdate } from '~/hooks/crud'
import { useGetSearchParams } from '~/hooks/urls'
import { useRedirect } from '~/hooks/app'

export function useRedirectDetail () {
  return useRedirect(ROUTES.PROVIDER_DETAIL_URL)
}

export function useRedirectEdit () {
  return useRedirect(ROUTES.PROVIDER_UPDATE_URL)
}

export function useProviderList () {
  const params = useGetSearchParams()
  return useList(API.PROVIDER_LIST, params)
}

export function useProviderDetail (id) {
  const api = sprintf(API.PROVIDER_DETAIL, id)
  return useDetail(api)
}

export function useProviderDelete (id) {
  const api = sprintf(API.PROVIDER_DELETE, id)
  return useRemove(api)
}

export function useProviderCreate () {
  return useCreate(API.PROVIDER_CREATE)
}

export function useProviderUpdate (id) {
  const api = sprintf(API.PROVIDER_UPDATE, id)
  return useUpdate(api)
}
