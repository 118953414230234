import React from 'react'
import PropTypes from 'prop-types'
import { Box, Flex } from '@chakra-ui/react'

import { SideBar } from './SideBar'

import { NAV_KEYS } from '~/constants/nav'

function AppBar (props) {
  const { children, activeNav } = props

  return (
    <Flex h={'100vh'}>
      <SideBar activeNav={activeNav} />
      <Box
        alignSelf={'baseline'}
        flexGrow={'1'}
        h={'full'}
        p={8}
        overflowY={'auto'}>
        {children}
      </Box>
    </Flex>
  )
}

AppBar.propTypes = {
  children: PropTypes.node.isRequired,
  activeNav: PropTypes.oneOf(Object.values(NAV_KEYS))
}

export default AppBar
