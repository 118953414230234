import React from 'react'
import PropTypes from 'prop-types'
import { Box, Stack, useColorModeValue as mode } from '@chakra-ui/react'

function CardDescription (props) {
  const { title, value } = props

  return (
    <Stack
      as={'dl'}
      direction={'row'}
      px={6}
      py={4}
      spacing={3}
      rounded={'md'}
      _even={{ bg: mode('gray.50', 'gray.900') }}>
      <Box
        as={'dt'}
        color={mode('gray.500', 'gray.400')}
        flexBasis={'25%'}
        fontWeight={'medium'}>
        {title}:
      </Box>
      <Box as={'dd'} flex={'1'}>
        {value}
      </Box>
    </Stack>
  )
}

CardDescription.propTypes = {
  title: PropTypes.string,
  value: PropTypes.node
}

export default CardDescription
