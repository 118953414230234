import React from 'react'
import { prop } from 'ramda'
import { useHistory, useParams } from 'react-router-dom'

import { userCreateSerializer } from '../serializers'
import { useUserDetail, useUserUpdate } from '../hooks'
import { UserCreate } from '../components/UserCreate'

import { RouterNavBar } from '~/components/NavBar'
import useToast, { SUCCESS_UPDATE_PARAMS } from '~/hooks/useToast'

export default function UserUpdateContainer () {
  const { id } = useParams()
  const history = useHistory()
  const toast = useToast()
  const { data, isLoading: isLoadingData } = useUserDetail(id)
  const { update, isLoading } = useUserUpdate(id)

  function onSubmit (formValues) {
    return update(userCreateSerializer(formValues))
      .then(() => toast(SUCCESS_UPDATE_PARAMS))
      .then(() => history.goBack())
  }

  const initialValues = {
    ...data,
    location: [
      prop('lat', data),
      prop('lon', data)
    ]
  }

  return (
    <RouterNavBar title={'User edit'}>
      <UserCreate
        onSubmit={onSubmit}
        isLoading={isLoading}
        isLoadingData={isLoadingData}
        initialValues={initialValues}
      />
    </RouterNavBar>
  )
}
