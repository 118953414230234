import React from 'react'

import {
  useRedirectDetail,
  useRedirectEdit,
  useMeasurementList
} from '../hooks'
import { MeasurementList } from '../components/MeasurementList'

import { NAV_KEYS } from '~/constants/nav'
import { AppBar } from '~/components/AppBar'

export default function MeasurementListContainer () {
  const list = useMeasurementList()
  const toDetail = useRedirectDetail()
  const onEdit = useRedirectEdit()

  return (
    <AppBar activeNav={NAV_KEYS.SETTINGS}>
      <MeasurementList
        list={list}
        toDetail={toDetail}
        onEdit={onEdit}
      />
    </AppBar>
  )
}
