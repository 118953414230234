import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import { Input, ImageCrop, withForm } from '~/components/FormElements'
import { Card, CardButtons, CardGrid, FieldStack } from '~/components/Card'

function BannerCreate (props) {
  const { form, isLoading, isLoadingData } = props
  const { handleSubmit } = form

  return (
    <Card as={'form'} onSubmit={handleSubmit} isLoading={isLoadingData}>
      <CardGrid>
        <FieldStack>
          <Field
            name={'title'}
            component={Input}
            label={'Title'}
            placeholder={'Title'}
            isRequired={true}
          />
          <Field
            name={'photo'}
            component={ImageCrop}
            label={'Image'}
            isRequired={true}
            height={'300px'}
            width={'full'}
            aspect={2}
          />
        </FieldStack>
      </CardGrid>

      <CardButtons isLoading={isLoading} />
    </Card>
  )
}

BannerCreate.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingData: PropTypes.bool
}

export default withForm(BannerCreate)
