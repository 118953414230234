import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { Box, Flex, Stack, useColorModeValue as mode } from '@chakra-ui/react'

import Logo from './Logo'
import NavItem from './NavItem'
import UserInfo from './UserInfo'

import nav from '~/constants/nav'
import Paper from '~/components/Paper'
import { ColorModeSwitcher } from '~/components/ColorModeSwitcher'

export function SideBar (props) {
  const { activeNav } = props

  return (
    <Box py={8} pl={8}>
      <Paper
        bgColor={mode('white', 'gray.800')}
        height={'full'}
        w={'xs'}>
        <Flex direction={'column'} justify={'space-between'} h={'full'} overflowY={'auto'}>
          <Box flexGrow={'1'} pb={20}>
            <Flex align={'center'} justify={'space-between'}>
              <Logo />
              <ColorModeSwitcher />
            </Flex>

            <Stack mt={10} spacing={2}>
              {nav.map(navItem => {
                const key = prop('key', navItem)
                const isActive = activeNav === key

                return (
                  <NavItem key={key} isActive={isActive} {...navItem} />
                )
              })}
            </Stack>
          </Box>

          <UserInfo />
        </Flex>
      </Paper>
    </Box>
  )
}

SideBar.propTypes = {
  activeNav: PropTypes.string.isRequired
}
