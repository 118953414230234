import React from 'react'
import { Switch } from 'react-router-dom'

import RouteWithSubRoutes from './RouteWithSubRoutes'

import routes from '~/routes'

export default function AppRouter () {
  return (
    <Switch>
      {routes.map((route, key) => (
        <RouteWithSubRoutes key={key} {...route} />
      ))}
    </Switch>
  )
}
