import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { ArrowLeft } from 'react-feather'
import { Box, Flex, IconButton, Heading } from '@chakra-ui/react'

import NavBarContainer from './NavBarContainer'

import Container from '~/components/Container'

function RouterNavBar (props) {
  const { title, children } = props

  const history = useHistory()

  function goBack () {
    history.goBack()
  }

  return (
    <Container py={8}>
      <NavBarContainer pl={4} pr={8} py={4}>
        <Flex align={'center'} flexGrow={'1'}>
          <IconButton
            aria-label={'Go back'}
            icon={<ArrowLeft />}
            color={'current'}
            colorScheme={'gray'}
            variant={'ghost'}
            onClick={goBack}
          />

          <Heading fontSize={'xl'} fontWeight={'medium'} ml={5}>
            {title}
          </Heading>
        </Flex>
      </NavBarContainer>
      <Box py={10}>{children}</Box>
    </Container>
  )
}

RouterNavBar.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default RouterNavBar
