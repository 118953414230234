import React from 'react'
import PropTypes from 'prop-types'
import { Image } from '@chakra-ui/react'

function CardImage (props) {
  const { src, boxSize, ...restProps } = props

  return (
    <Image
      boxSize={boxSize}
      display={'block'}
      src={src}
      objectFit={'cover'}
      minW={boxSize}
      rounded={'md'}
      {...restProps}
    />
  )
}

CardImage.propTypes = {
  boxSize: PropTypes.string,
  src: PropTypes.string
}

CardImage.defaultProps = {
  boxSize: '184px'
}

export default CardImage
