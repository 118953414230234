import React from 'react'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'

import {
  PhotoGrid,
  Grid,
  PhotoGridCard,
  PhotoGridImage,
  PhotoGridTitle
} from '~/components/PhotoGrid'

function BannerList (props) {
  const { list, toDetail } = props

  const { results, isLoading } = list

  return (
    <PhotoGrid
      title={'Banners'}
      list={results}
      isLoading={isLoading}>
      <Grid>
        {results.map(item => {
          const id = prop('id', item)
          const title = prop('title', item)
          const photo = path(['photo', 'path'], item)

          return (
            <PhotoGridCard key={id} onClick={toDetail.bind(null, id)}>
              <PhotoGridImage src={photo} />
              <PhotoGridTitle>{title}</PhotoGridTitle>
            </PhotoGridCard>
          )
        })}
      </Grid>
    </PhotoGrid>
  )
}

BannerList.propTypes = {
  list: PropTypes.object.isRequired,
  toDetail: PropTypes.func.isRequired
  // onEdit: PropTypes.func.isRequired
}

export default BannerList
