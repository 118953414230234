import React from 'react'
import PropTypes from 'prop-types'
import {
  NumberInput as ChakraNumberInput,
  NumberInputField
} from '@chakra-ui/react'

import FormControl from '../FormControl'

function NumberInput (props) {
  const {
    input,
    meta,
    label,
    isRequired,
    leftElement,
    rightElement,
    ...restProps
  } = props

  return (
    <FormControl
      id={input.name}
      isRequired={isRequired}
      label={label}>
      <ChakraNumberInput {...input}>
        <NumberInputField
          inputMode={'numeric'}
          {...restProps}
        />
      </ChakraNumberInput>
    </FormControl>
  )
}

NumberInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  leftElement: PropTypes.node,
  rightElement: PropTypes.node
}

export default NumberInput
