import { pipe } from 'ramda'
import { useLocation } from 'react-router-dom'

import { parseParams } from './utils'

export default function useGetSearchParams () {
  const { search } = useLocation()

  return pipe(
    decodeURIComponent,
    parseParams
  )(search)
}
