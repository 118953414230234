import { useHistory } from 'react-router-dom'
import { sprintf } from 'sprintf-js'
import { is } from 'ramda'

export default function useRedirect (redirectUrl) {
  const history = useHistory()

  return function (value) {
    if (is(Number, value)) { // single value
      history.push(sprintf(redirectUrl, value))
    } else if (is(Array, value)) { // multi value
      history.push(sprintf(redirectUrl, ...value))
    } else { // no value
      history.push(redirectUrl)
    }
  }
}
