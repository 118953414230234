import React from 'react'
import { Skeleton } from '@chakra-ui/react'

import { useSkeletonColors } from './useSkeletonColors'

import { Grid, PhotoGridCard, PhotoGridTitle } from '~/components/PhotoGrid'

function PhotoSkeleton () {
  const colors = useSkeletonColors()

  return (
    <PhotoGridCard pointerEvents={'none'}>
      <Skeleton h={40} {...colors.light} />
      <PhotoGridTitle as={'div'}>
        <Skeleton h={4} w={'75%'} {...colors.dark} />
      </PhotoGridTitle>
    </PhotoGridCard>
  )
}

export default function PhotoGridSkeleton (props) {
  return (
    <Grid>
      <PhotoSkeleton />
      <PhotoSkeleton />
      <PhotoSkeleton />
      <PhotoSkeleton />
      <PhotoSkeleton />
      <PhotoSkeleton />
    </Grid>
  )
}
