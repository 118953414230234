import React from 'react'
import PropTypes from 'prop-types'

import Container from '~/components/Container'

function BlankLayout ({ children }) {
  return (
    <Container px={0}>
      {children}
    </Container>
  )
}

BlankLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default BlankLayout
