/* eslint-disable max-len */
import React from 'react'
import { Icon, useToken, useColorModeValue } from '@chakra-ui/react'

export default function LogoSvg (props) {
  const [primary500, white] = useToken('colors', ['primary.500', 'white'])
  const primaryFill = useColorModeValue(primary500, white)
  const secondaryFill = useColorModeValue(white, primary500)

  return (
    <Icon viewBox={'0 0 200 73.1'} {...props}>
      <path fill={primaryFill} d={'M102.5,49.9c-2.5,0-4.7-0.6-6.4-1.7c-1.7-1.1-2.9-2.7-3.7-4.6l4.3-2.5c1,2.6,3,3.9,5.9,3.9 c1.4,0,2.4-0.3,3.1-0.8c0.7-0.5,1-1.2,1-1.9c0-0.9-0.4-1.6-1.2-2.1c-0.8-0.5-2.2-1.1-4.3-1.6c-1.1-0.3-2.1-0.7-2.9-1 c-0.8-0.3-1.6-0.8-2.4-1.4c-0.8-0.6-1.4-1.3-1.8-2.2c-0.4-0.9-0.6-1.9-0.6-3.1c0-2.3,0.8-4.2,2.4-5.5c1.7-1.4,3.6-2.1,6-2.1 c2.1,0,3.9,0.5,5.4,1.5c1.6,1,2.8,2.4,3.7,4.2l-4.2,2.4c-1-2.2-2.7-3.3-4.9-3.3c-1,0-1.9,0.2-2.5,0.7c-0.6,0.5-0.9,1.1-0.9,1.8 c0,0.8,0.3,1.5,1,2c0.7,0.5,2,1,3.9,1.6c0.8,0.2,1.4,0.4,1.8,0.6c0.4,0.1,1,0.3,1.6,0.6c0.7,0.3,1.2,0.5,1.6,0.8 c0.4,0.2,0.8,0.6,1.3,1c0.5,0.4,0.9,0.8,1.1,1.3c0.3,0.4,0.5,1,0.7,1.6c0.2,0.6,0.3,1.3,0.3,2c0,2.4-0.9,4.2-2.6,5.6 C107.5,49.2,105.3,49.9,102.5,49.9z M121.4,44.5h10.8v4.8h-15.8V23.8H132v4.8h-10.6V34h9.7v4.8h-9.7V44.5z M154.1,23.8v4.8h-6.9 v20.8h-5V28.6h-6.9v-4.8H154.1z M168,49.9c-2.9,0-5.2-0.8-7-2.4c-1.8-1.6-2.7-3.8-2.7-6.5V23.8h5v16.8c0,1.3,0.4,2.4,1.1,3.2 c0.8,0.8,2,1.2,3.6,1.2c1.6,0,2.8-0.4,3.6-1.2c0.8-0.8,1.2-1.8,1.2-3.2V23.8h5V41c0,2.7-0.9,4.9-2.7,6.5 C173.2,49.1,170.8,49.9,168,49.9z M189.1,44.5H200v4.8h-17.3v-4l10.6-16.7H183v-4.8h16.8v4L189.1,44.5z'} />
      <circle fill={primaryFill} cx={'36.6'} cy={'36.6'} r={'36.6'} />
      <path fillRule={'evenodd'} clipRule={'evenodd'} fill={secondaryFill} d={'M44.1,28.9c0-0.1,0.1-0.2,0.1-0.3h0c-0.2-4.1-3.5-7.3-7.6-7.3s-7.4,3.2-7.6,7.3c0,0.1,0,0.2,0,0.3h-0.1 c-2,0-4,1.3-4.6,4.8L23,43.1c-1,6.9,2.6,8.7,7.3,8.7h12.6c4.6,0,8.1-2.5,7.2-8.7L49,33.7c-0.7-3.4-2.7-4.8-4.7-4.8H44.1z M41.9,28.9 c0-0.1-0.1-0.2-0.1-0.3c0-2.9-2.4-5.3-5.3-5.3c-2.9,0-5.3,2.4-5.3,5.3c0,0.1,0,0.2,0,0.3H41.9z M32.1,36.8c-0.7,0-1.3-0.6-1.3-1.4 c0-0.8,0.6-1.4,1.3-1.4c0.7,0,1.3,0.6,1.3,1.4C33.5,36.2,32.9,36.8,32.1,36.8z M39.6,35.4c0,0.8,0.6,1.4,1.3,1.4 c0.7,0,1.3-0.6,1.3-1.4c0-0.8-0.6-1.4-1.3-1.4C40.2,34,39.6,34.6,39.6,35.4z'} />
    </Icon>
  )
}
