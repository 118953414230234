import React from 'react'

import { Dashboard } from '../components'

import { NAV_KEYS } from '~/constants/nav'
import { useFilter } from '~/hooks/app'
import { AppBar } from '~/components/AppBar'

export default function DashboardContainer () {
  const filter = useFilter({
    fields: ['key1', 'key2']
  })
  return (
    <AppBar activeNav={NAV_KEYS.DASHBOARD}>
      <Dashboard filter={filter} />
    </AppBar>
  )
}
