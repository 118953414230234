export default {
  defaultProps: {
    colorScheme: 'primary'
  },
  baseStyle: {
    fontWeight: 'medium',
    _focus: {
      boxShadow: 'none'
    }
  },
  sizes: {
    md: {
      fontSize: 'md'
    },
    lg: {
      fontSize: 'md'
    }
  }
}
