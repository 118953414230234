import { prop, path } from 'ramda'

export const providerCreateSerializer = values => ({
  photoId: path(['photo', 'id'], values),
  name: prop('name', values),
  inn: prop('inn', values),
  cashback: prop('cashback', values),
  minOrderPrice: prop('minOrderPrice', values),
  description: prop('description', values),
  address: prop('address', values)
})
