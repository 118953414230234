import { pipe, pick } from 'ramda'

import useGetSearchParams from './useGetSearchParams'

import toSnakeCase from '~/utils/toSnakeCase'

export default function usePickSearchParams (keys, modifier = toSnakeCase) {
  const searchParams = useGetSearchParams()

  return pipe(
    pick(keys),
    modifier
  )(searchParams)
}
