import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

function RouteWithSubRoutes (route) {
  return (
    <Fragment>
      <Route
        exact={true}
        path={route.path}
        render={props => (
          <route.layout>
            <route.component {...props} />
          </route.layout>
        )}
      />
      {route.routes.map((route, index) => (
        <RouteWithSubRoutes key={index} {...route} />
      ))}
    </Fragment>
  )
}

RouteWithSubRoutes.propTypes = {
  path: PropTypes.string.isRequired,
  layout: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  component: PropTypes.any.isRequired,
  routes: PropTypes.array
}

RouteWithSubRoutes.defaultProps = {
  routes: []
}

export default RouteWithSubRoutes
