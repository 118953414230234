import {
  CategoryListContainer,
  CategoryDetailContainer,
  CategoryCreateContainer,
  CategoryUpdateContainer
} from './containers'

import * as ROUTES from '~/constants/routes'
import { MainLayout } from '~/components/Layouts'

export default [{
  path: ROUTES.CATEGORY_LIST_PATH,
  layout: MainLayout,
  component: CategoryListContainer,
  routes: [
    {
      path: ROUTES.CATEGORY_DETAIL_PATH,
      layout: MainLayout,
      component: CategoryDetailContainer
    },
    {
      path: ROUTES.CATEGORY_CREATE_PATH,
      layout: MainLayout,
      component: CategoryCreateContainer
    },
    {
      path: ROUTES.CATEGORY_UPDATE_PATH,
      layout: MainLayout,
      component: CategoryUpdateContainer
    }
  ]
}]
