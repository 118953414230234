import {
  Database,
  Star,
  Grid,
  DollarSign,
  Settings,
  Users
} from 'react-feather'

import * as ROUTES from './routes'

export const NAV_KEYS = {
  DASHBOARD: 'dashboard',
  PROVIDERS: 'providers',
  PRODUCTS: 'products',
  ORDER: 'order',
  BRANDS: 'brands',
  SETTINGS: 'settings',
  USERS: 'users'
}

export default [
  {
    key: NAV_KEYS.PROVIDERS,
    label: 'Providers',
    href: ROUTES.PROVIDER_LIST_PATH,
    icon: Database
  },
  {
    key: NAV_KEYS.BRANDS,
    label: 'Brands',
    href: ROUTES.BRAND_LIST_PATH,
    icon: Star
  },
  {
    key: NAV_KEYS.PRODUCTS,
    label: 'Products',
    icon: Grid,
    children: [
      {
        label: 'Products',
        href: ROUTES.PRODUCT_LIST_PATH
      },
      {
        label: 'Categories',
        href: ROUTES.CATEGORY_LIST_PATH
      }
    ]
  },
  {
    key: NAV_KEYS.ORDER,
    label: 'Orders',
    href: ROUTES.ORDER_LIST_PATH,
    icon: DollarSign
  },
  {
    key: NAV_KEYS.USERS,
    label: 'Users',
    href: ROUTES.USER_LIST_PATH,
    icon: Users
  },
  {
    key: NAV_KEYS.SETTINGS,
    label: 'Settings',
    icon: Settings,
    children: [
      {
        label: 'Banners',
        href: ROUTES.BANNER_LIST_PATH
      },
      {
        label: 'Regions',
        href: ROUTES.REGION_LIST_PATH
      },
      {
        label: 'Measurements',
        href: ROUTES.MEASUREMENT_LIST_PATH
      },
      {
        label: 'Tokens',
        href: ROUTES.TOKEN_LIST_PATH
      }
    ]
  }
]
