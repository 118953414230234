import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from '@chakra-ui/react'

import Paper from '~/components/Paper'

function NavBarContainer (props) {
  const { children, ...restProps } = props

  return (
    <Flex
      as={Paper}
      align={'center'}
      minH={'60px'}
      p={0}
      {...restProps}>
      {children}
    </Flex>
  )
}

NavBarContainer.propTypes = {
  children: PropTypes.node.isRequired
}

export default NavBarContainer
