import React from 'react'
import PropTypes from 'prop-types'
import {
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Input as ChakraInput
} from '@chakra-ui/react'

import FormControl from '../FormControl'

function Input (props) {
  const {
    input,
    meta,
    label,
    isRequired,
    leftElement,
    rightElement,
    ...restProps
  } = props

  return (
    <FormControl
      id={input.name}
      isRequired={isRequired}
      label={label}>
      <InputGroup>
        {leftElement && (
          <InputLeftElement pointerEvents={'none'}>
            {leftElement}
          </InputLeftElement>
        )}
        <ChakraInput {...input} {...restProps} />
        {rightElement && (
          <InputRightElement pointerEvents={'none'}>
            {rightElement}
          </InputRightElement>
        )}
      </InputGroup>
    </FormControl>
  )
}

Input.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  leftElement: PropTypes.node,
  rightElement: PropTypes.node
}

export default Input
