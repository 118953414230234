import React from 'react'
import PropTypes from 'prop-types'
import isEqual from 'react-fast-compare'
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ButtonGroup,
  Button
} from '@chakra-ui/react'

function Modal (props) {
  const {
    title,
    isOpen,
    onClose,
    onSubmit,
    submitText,
    children,
    ...restProps
  } = props

  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      preserveScrollBarGap={true}
      motionPreset={'slideInBottom'}
      size={'lg'}
      {...restProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton _focus={{ boxShadow: 'none' }} />

        <ModalBody>
          {children}
        </ModalBody>

        <ModalFooter>
          <ButtonGroup spacing={4}>
            <Button colorScheme={'gray'} variant={'ghost'} onClick={onClose}>
              Close
            </Button>
            {onSubmit && (
              <Button onClick={onSubmit}>
                {submitText}
              </Button>
            )}
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  )
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  submitText: PropTypes.string
}

Modal.defaultProps = {
  onSubmit: null,
  submitText: 'Submit'
}

export default React.memo(Modal, isEqual)
