import React from 'react'
import { prop } from 'ramda'

import SearchFieldBase from './SearchFieldBase'
import {
  getStaticOption,
  getStaticOptions,
  defaultGetText,
  defaultGetValue,
  searchFieldStaticPropTypes
} from './utils'

function SearchFieldStatic (props) {
  const { list, itemText } = props

  function filterOption (option, input) {
    const words = input.split(' ')
    function reducer (acc, cur) {
      const label = prop('label', option)
      return (acc && label) && label.toLowerCase().includes(cur.toLowerCase())
    }
    return words.reduce(reducer, true)
  }

  return (
    <SearchFieldBase
      getText={defaultGetText(itemText)}
      getValue={defaultGetValue(['id'])}
      getOptions={search => getStaticOptions(search, list)}
      getOption={id => getStaticOption(id, list)}
      isStatic={true}
      isClearable={true}
      filterOption={filterOption}
      {...props}
    />
  )
}

SearchFieldStatic.propTypes = {
  ...searchFieldStaticPropTypes
}

SearchFieldStatic.defaultProps = {
  itemText: ['name']
}

export default SearchFieldStatic
