import {
  ProviderListContainer,
  ProviderDetailContainer,
  ProviderCreateContainer,
  ProviderUpdateContainer
} from './containers'

import * as ROUTES from '~/constants/routes'
import { MainLayout } from '~/components/Layouts'

export default [{
  path: ROUTES.PROVIDER_LIST_PATH,
  layout: MainLayout,
  component: ProviderListContainer,
  routes: [
    {
      path: ROUTES.PROVIDER_DETAIL_PATH,
      layout: MainLayout,
      component: ProviderDetailContainer
    },
    {
      path: ROUTES.PROVIDER_CREATE_PATH,
      layout: MainLayout,
      component: ProviderCreateContainer
    },
    {
      path: ROUTES.PROVIDER_UPDATE_PATH,
      layout: MainLayout,
      component: ProviderUpdateContainer
    }
  ]
}]
