import { mode } from '@chakra-ui/theme-tools'

export default {
  baseStyle: props => ({
    list: {
      bg: mode('white', 'gray.800')(props),
      color: mode('palette.text', 'white')(props)
    },
    item: {
      px: 5,
      py: 2,
      _hover: {
        bg: mode('gray.200', 'whiteAlpha.200')(props)
      }
    }
  })
}
