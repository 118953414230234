import React from 'react'
import { useParams } from 'react-router-dom'

import { useRedirectEdit, useOrderDetail, useOrderDelete } from '../hooks'
import { OrderDetail } from '../components/OrderDetail'

import { useConfirm } from '~/hooks/app'
import { RouterNavBar } from '~/components/NavBar'

export default function OrderDetailContainer () {
  const { id } = useParams()
  const detail = useOrderDetail(id)
  const remove = useOrderDelete(id)

  const onDelete = useConfirm({
    remove,
    alertParams: { title: 'Remove region' },
    redirectPath: 'back'
  })
  const onEdit = useRedirectEdit()

  return (
    <RouterNavBar title={'Order detail'}>
      <OrderDetail
        detail={detail}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </RouterNavBar>
  )
}
