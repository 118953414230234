import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { orderCreateSerializer } from '../serializers'
import { useOrderDetail, useOrderUpdate } from '../hooks'
import { OrderCreate } from '../components/OrderCreate'

import { RouterNavBar } from '~/components/NavBar'
import useToast, { SUCCESS_UPDATE_PARAMS } from '~/hooks/useToast'

export default function OrderUpdateContainer () {
  const { id } = useParams()
  const history = useHistory()
  const toast = useToast()
  const { data, isLoading: isLoadingData } = useOrderDetail(id)
  const { update, isLoading } = useOrderUpdate(id)

  function onSubmit (formValues) {
    return update(orderCreateSerializer(formValues))
      .then(() => toast(SUCCESS_UPDATE_PARAMS))
      .then(() => history.goBack())
  }

  return (
    <RouterNavBar title={'Order edit'}>
      <OrderCreate
        onSubmit={onSubmit}
        isLoading={isLoading}
        isLoadingData={isLoadingData}
        initialValues={data}
      />
    </RouterNavBar>
  )
}
