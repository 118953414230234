import React from 'react'
import { Button, Flex, Stack } from '@chakra-ui/react'
import { Plus } from 'react-feather'

import { useTable } from './TableProvider'
import TableFilter from './TableFilter'
import TableSearch from './TableSearch'

import { Icon } from '~/components/Chakra'
import Link from '~/components/Link'

export default function TableActions () {
  const { isCreatable } = useTable()

  return (
    <Flex align={'center'} justify={'space-between'}>
      <TableFilter />

      <Stack direction={'row'} ml={'auto'} spacing={4}>
        {isCreatable && (
          <Button
            as={Link}
            to={'create/'}
            leftIcon={<Icon as={Plus} />}
            minW={'unset'}>
            Create
          </Button>
        )}
        <TableSearch />
      </Stack>
    </Flex>
  )
}
