import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { find, pipe, prop, propEq, propOr } from 'ramda'

import { productCreateSerializer } from '../serializers'
import { useProductDetail, useProductUpdate } from '../hooks'
import { ProductCreate } from '../components/ProductCreate'

import { RouterNavBar } from '~/components/NavBar'
import useToast, { SUCCESS_UPDATE_PARAMS } from '~/hooks/useToast'

export default function ProductUpdateContainer () {
  const { id } = useParams()
  const history = useHistory()
  const toast = useToast()
  const { data, isLoading: isLoadingData } = useProductDetail(id)
  const { update, isLoading } = useProductUpdate(id)

  function onSubmit (formValues) {
    return update(productCreateSerializer(formValues))
      .then(() => toast(SUCCESS_UPDATE_PARAMS))
      .then(() => history.goBack())
  }

  const initialValues = {
    ...data,
    photo: pipe(
      propOr([], 'productImages'),
      find(propEq('isMain', true)),
      prop('photo')
    )(data)
  }

  return (
    <RouterNavBar title={'Product edit'}>
      <ProductCreate
        onSubmit={onSubmit}
        isLoading={isLoading}
        isLoadingData={isLoadingData}
        initialValues={initialValues}
      />
    </RouterNavBar>
  )
}
