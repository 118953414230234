import { sprintf } from 'sprintf-js'

import * as API from '~/constants/api'
import * as ROUTES from '~/constants/routes'
import { useCreate, useDetail, useList, useRemove, useUpdate } from '~/hooks/crud'
import { useGetSearchParams } from '~/hooks/urls'
import { useRedirect } from '~/hooks/app'

export function useRedirectDetail () {
  return useRedirect(ROUTES.MEASUREMENT_DETAIL_URL)
}

export function useRedirectEdit () {
  return useRedirect(ROUTES.MEASUREMENT_UPDATE_URL)
}

export function useMeasurementList () {
  const params = useGetSearchParams()
  return useList(API.MEASUREMENT_LIST, params)
}

export function useMeasurementDetail (id) {
  const api = sprintf(API.MEASUREMENT_DETAIL, id)
  return useDetail(api)
}

export function useMeasurementDelete (id) {
  const api = sprintf(API.MEASUREMENT_DELETE, id)
  return useRemove(api)
}

export function useMeasurementCreate () {
  return useCreate(API.MEASUREMENT_CREATE)
}

export function useMeasurementUpdate (id) {
  const api = sprintf(API.MEASUREMENT_UPDATE, id)
  return useUpdate(api)
}
